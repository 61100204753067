import { Helmet } from "react-helmet";
export interface PageTestimonialProps {
  className?: string;
}

export default function Testimonial({
  className = "",
}: PageTestimonialProps) {
  return (
    <>
      <Helmet>
        <title>Testimonial Release | Backpac</title>
        <meta
          name="description"
          content={"Backpac's Testimonial Release"}
        ></meta>
        <meta
          name="keywords"
          content={
            "Smart RPC Load Balancer pricing, price, subscription, Blockchain RPC Connectivity, Customizable Configurations, Load Balancing, High-Performance RPC, Reputable Providers, Fault Tolerance, Regional Load Balancing, Blockchain Network, API Key, Application Endpoint, Latency Optimization, Performance Enhancement, Custom Load Balancer Rules, RPC Method Routing, Block Height Routing, Smart Caching, Custom Target Groups, Top RPC Providers, Custom RPC Providers"
          }
        ></meta>
        <meta
          name="twitter:image"
          content="https://image.nft.backpac.xyz/crop.png"
        />
        <meta
          name="twitter:image:alt"
          content="Backpac's Testimonial Release"
        />
        <meta
          property="og:image"
          content="https://image.nft.backpac.xyz/ICON_WHITE_1024_1024.jpg"
        />
        <meta property="og:image:alt" content="Backpac's Testimonial Release" />
      </Helmet>
      <div
        className={`nc-Testimonial container pb-24 lg:pb-32 ${className}`}
        data-nc-id="Testimonial"
      >
        <div className="flex min-h-screen items-center justify-center">
          <section>
            <div className="mb-24 text-center">
              <h1 className="block antialiased font-sans relative my-5 text-center text-4xl font-bold leading-tight tracking-normal text-black md:text-5xl">
                {" "}
                Testimonial Release{" "}
              </h1>
            </div>
            <div className="grid grid-cols-12 ">
              <div className="col-span-12">
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  You (the "Company") hereby grants to Backpac Inc ("Backpac"),
                  and its subsidiaries, parent, affiliates, successors, assigns,
                  and to other such persons and/or entities as Backpac may
                  designate (collectively the "Released Parties"), the
                  irrevocable right and permission to reproduce, publish,
                  distribute, display, broadcast, exhibit, and/or in any other
                  way use of the Company's name, image, logo, likeness,
                  photograph, pictures, without limitation (collectively,
                  "Persona"), either alone or together with statements or
                  stories concerning Backpac's products and services
                  (collectively, the "Statements") in one or more recordings,
                  videos, and still images, including, without limitation,
                  internet and digital materials, and in press, and publicity
                  materials, either alone or accompanied by other material,
                  worldwide, in perpetuity, and royalty- free, for any lawful
                  purpose including, without limitation, the purpose of
                  advertising, publicity, promotion, trade and/or other
                  marketing for Backpac in any and all media now known or
                  hereafter developed, including, without limitation, on the
                  Internet, and/or other digital media (collectively, the
                  "Materials"). For purposes of this Release, Company shall also
                  include any of such party's employees, participants or other
                  representatives participating in the activities giving rise to
                  the Statements and/or Materials.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  The Company will make the Statements without any prior payment
                  or promise of payment, or any other benefit having been made
                  to the Company and without any expectation by the Company of
                  any payment or benefit in return. The Company acknowledges and
                  agrees that the Statements may be used in whole or in part and
                  may be paraphrased, amplified, modified, shortened and/or put
                  into conversational form.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  The Company hereby waives any right it may have to inspect
                  and/or approve the use of the Company's Persona and/or the
                  Statements pursuant to this Release, including, but not
                  limited to, any Materials using the Persona and/or the
                  Statements and/or any text, image and/or other creative
                  elements that may be used in conjunction with the Company's
                  Persona and/or the Statements. Nothing herein will constitute
                  any obligation on the part of Backpac to make any use of any
                  of the rights granted herein.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  The Company hereby releases, discharges and agrees to hold
                  harmless the Released Parties from any claims, actions, suits,
                  losses, liabilities, damages, costs, expenses and/or fees
                  (including, without limitation, counsel fees and
                  disbursements) arising out of and/or relating to the use of
                  the Persona, the Statements, and/or the Materials in
                  accordance with the terms of this Release, including, but not
                  limited to, claims in the nature of copyright infringement,
                  defamation, disparagement, slander, false light, violation of
                  the right of privacy or publicity or the like.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  The undersigned represents and warrants that: (i) the
                  undersigned has the full right and authority to enter into
                  this Release and to grant all of the rights and releases
                  granted herein; and (ii) the undersigned has carefully and
                  thoroughly read, understood and approves of this Release
                  before executing it, and agrees to be bound by all of its
                  provisions. This Release contains the entire understanding
                  between the Company and Backpac regarding the use of the
                  Company's Persona and the Statements, supersedes all prior
                  agreements between the Company and Backpac regarding this
                  matter. The Company may at any time send notice to
                  support@Backpac.xyz stating its desire to revoke permission of the
                  use of the Company's Persona and Statements, in whole, and
                  Backpac shall use its commercially reasonable efforts to
                  comply with such request. This Release shall be governed by
                  the laws of the State of Florida without regard to its choice
                  of law or conflicts of law principles.
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
