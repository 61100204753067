import Endpoint from "./Endpoint";
import Developer from "../interfaces/Developer";
export default class Company {
  state: string;
  companyId: string | null;
  name: string | null;
  email: string | null;
  description: string | null;
  website: string | null;
  twitter: string | null;
  facebook: string | null;
  imgUrl: string | null;
  status: boolean;
  companyuuid: string | null;
  developer: Developer | null;
  timeStamp: string | null;
  isoDate: string | null;
  week: number | null;
  created: string | null;
  updatedAt: string | null;

  constructor(
    state: string,
    companyId: string | null,
    name: string,
    email: string | null,
    description: string | null,
    website: string | null,
    twitter: string | null,
    facebook: string | null,
    imgUrl: string | null,
    developer: Developer | null
  ) {
    this.state = state;
    this.companyId = companyId || null;

    this.name = name || null;
    this.email = email || null;

    this.description = description || null;
    this.website = website || null;

    this.twitter = twitter || null;
    this.facebook = facebook || null;

    this.imgUrl = imgUrl || null;

    this.developer = developer || null;

    this.companyuuid = null;
    this.timeStamp = null;
    this.isoDate = null;
    this.week = null;
    this.created = null;
    this.updatedAt = null;

    this.status = true;
  }

  get = async () => {
    const _Endpoint = new Endpoint();
    const company = await _Endpoint.get("");
    console.log(company);
  };
  create = async (did_token: string) => {
    const _Endpoint = new Endpoint();
    const create = await _Endpoint.postApiClientWithAdditionalParams(
      "/company",
      this,
      {
        headers: { did_token, platform: "desktop" },
      }
    );
    console.log(create)
  };
  update = async (did_token: string) => {
    const _Endpoint = new Endpoint();
    const create = await _Endpoint.patchApiClientWithAdditionalParams(
      "/company",
      this,
      {
        headers: { did_token, platform: "desktop" },
      }
    );
    console.log(create)
  };
  delete = () => {};
  exists = () => {};
}