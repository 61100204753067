const Features = () => {

    const features = [
    //   {
    //     icon: (
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         strokeWidth={1.5}
    //         stroke="#3BA5F3"
    //         className="w-6 h-6"
    //       >
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
    //         />
    //       </svg>
    //     ),
    //     title: "Low Latency RPC Endpoints",
    //     desc: "Enjoy lightning-speed RPC connectivity through Backpac's dynamic load balancing and intelligent caching, ensuring ultra-low latency for your blockchain applications.",
    //   },
      {
        icon: (
          <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#3BA5F3"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          className="w-6 h-6"
        >
          <path d="M20,9H16a1,1,0,0,0-1,1v1H7V7H8A1,1,0,0,0,9,6V2A1,1,0,0,0,8,1H4A1,1,0,0,0,3,2V6A1,1,0,0,0,4,7H5V20a1,1,0,0,0,1,1h9v1a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V18a1,1,0,0,0-1-1H16a1,1,0,0,0-1,1v1H7V13h8v1a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V10A1,1,0,0,0,20,9ZM5,3H7V5H5ZM17,19h2v2H17Zm2-6H17V11h2Z"/>
       </svg>

        ),
        title: "Redundancy",
        desc: "Distribute traffic across multiple RPC node providers, eliminating single points of failure and enhancing system reliability.",
      },
      {
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#3BA5F3"
            className="w-6 h-6"
          >
            <path d="M17 16v1h-17v-1h17zM5.203 7.976l4.204 3.026 5.593-6.251v2.284h1v-4.035h-4.036v1h2.366l-5.070 5.665-4.129-2.974-4.372 3.956 0.671 0.741 3.773-3.412z" fill="#3BA5F3" />
          </svg>
        ),
        title: "Increased Throughput",
        desc: "Experience increased rate limits and enhanced performance, ensuring seamless operation even under heavy workloads.",
      },
      {
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#3BA5F3"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
            />
          </svg>
        ),
        title: "Block Height Routing",
        desc: "Block height routing, a sophisticated routing mechanism that directs traffic to the RPC provider with the highest blockchain block height.",
      },
      {
        icon: (
          <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#3BA5F3"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 6L9 12.75l4.286-4.286a11.948 11.948 0 014.306 6.43l.776 2.898m0 0l3.182-5.511m-3.182 5.51l-5.511-3.181"
          />
        </svg>
        ),
        title: "Lower Utilization cost",
        desc: "Intelligent caching reduces node request & latency by selectively caching results based on block times. Combine cost-optimized routing with our smart caching solution and save even more.",
      },
    ];

    return (
        <section className="relative py-28 bg-gray-900">
            <div className="relative z-10 max-w-screen-xl mx-auto px-5 text-gray-300 justify-between gap-24 lg:flex md:px-8">
                <div className="max-w-xl">
                    <h3 className="text-white text-3xl font-semibold sm:text-4xl">
                    The <i>{'"Gateway"'}</i>
                    </h3>
                    <p className="mt-3">
                    Easily scale your Web3 applications throughput to meet growing demands without compromising performance with advanced load balancing and routing.
                    </p>
                </div>
                <div className="mt-12 lg:mt-0">
                    <ul className="grid gap-8 sm:grid-cols-2">
                        {
                            features.map((item, idx) => (
                                <li key={idx} className="flex gap-x-4">
                                    <div className="flex-none w-12 h-12 bg-gray-700 text-cyan-400 rounded-lg flex items-center justify-center">
                                        {item.icon}
                                    </div>
                                    <div>
                                        <h4 className="text-lg text-gray-100 font-semibold">
                                            {item.title}
                                        </h4>
                                        <p className="mt-3">
                                            {item.desc}
                                        </p>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
            <div className="absolute inset-0 max-w-md mx-auto h-72 blur-[118px]" style={{ background: "linear-gradient(152.92deg, rgba(59, 165, 243, 1) 4.54%, rgba(59, 165, 243, 0.5) 34.2%, rgba(59, 165, 243, 0.2) 77.55%)" }}></div>
        </section>
    )
}
export default Features;