import { CloudIcon, BookmarkIcon, ServerStackIcon } from '@heroicons/react/24/solid';
import ArweavePermaPage from "images/arweave/Arweave.png";
const features = [
  {
    name: 'Arweave Gateway',
    description: 'Backpac\'s Gateway enhances the speed to query Arweave data advanced caching and uninterrupted exploration via automatic Failover, guaranteeing reliability and resilience.',
    icon: CloudIcon,
  },
  {
    name: 'Caching',
    description: 'Smart caching of Arweave data ensures faster data retrieval. Quickly access commonly requested information, boosting the efficiency of exploring the globally distributed and permanent archive.',
    icon: BookmarkIcon,
  },
  {
    name: 'Failover',
    description: 'Our Arweave Gateway keeps Permaweb access uninterrupted with an automatic Peer Failover feature. If a peer fails, it smoothly switches to alternative peers or custom nodes.',
    icon: ServerStackIcon,
  },
]

export default function Benefits() {
  return (
    <div className="overflow-hidden bg-white dark:bg-neutral-900 py-8 sm:py-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600 dark:text-neutral-400">
                PermaWeb
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                Gateway Features
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-neutral-400">
                An Arweave Gateway is your entry point to the Permaweb, enabling
                easy access to a globally distributed and permanent archive.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 dark:text-neutral-400 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900 dark:text-white">
                      <feature.icon
                        className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={ArweavePermaPage}
            alt="Arweave Gateway"
            className="w-[22rem] sm:w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0"
          />
          {/* width={2432}
            height={1442} */}
        </div>
      </div>
    </div>
  );
}
