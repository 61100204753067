import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link } from "react-router-dom";
import BlogPostCardMeta from "components/PostCardMeta/BlogPostCardMeta";

export interface CardPostProps {
  className?: string;
  item: any;
}

const CardPost: FC<CardPostProps> = ({ className = "", item }) => {
  return (
    <div className={`nc-Card13 relative flex ${className}`} data-nc-id="Card13">
      <div className="flex flex-col h-full py-2">
        <h2 className={`nc-card-title block font-semibold text-base`}>
          <Link
            to={`/blog/${item.slug}`}
            className="line-clamp-2 capitalize"
            title={"title"}
          >
            {item.title}
          </Link>
        </h2>
        <span className="hidden sm:block my-3 text-neutral-500 dark:text-neutral-400 ">
          <span className="line-clamp-2">
            <div
              dangerouslySetInnerHTML={{ __html: item.description || "" }}
            ></div>
          </span>
          <div className="mt-auto hidden sm:block">
          <BlogPostCardMeta avatar={item.avatar} author={item.author} date={item.date}/>
        </div>
        </span>
        <span className="mt-4 block sm:hidden text-sm text-neutral-500 ">
          {item.date} · 2 min read
        </span>

      </div>

      <Link
        to={`/blog/${item.slug}`}
        className={`block relative h-full flex-shrink-0 w-2/5 sm:w-1/3 ml-3 sm:ml-5`}
      >
        <NcImage
          src={item.featured}
          containerClassName="absolute inset-0 "
          className="object-cover w-full rounded-xl sm:rounded-3xl"
        />
      </Link>
    </div>
  );
};

export default CardPost;
