
 const Plus = () => {
    const features = [
      {
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#1D476D"
            className="w-8 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
            />
          </svg>
        ),
        title: "Customization and Scalability",
        desc: "Personalize your configurations and expand with multiple RPC providers to accommodate evolving needs with flexibility.",
      },
      {
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#1D476D"
            className="w-8 h-8"
          >
            <path
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
              stroke="#1D476D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <g opacity="0.4">
              <path
                d="M7.99961 3H8.99961C7.04961 8.84 7.04961 15.16 8.99961 21H7.99961"
                stroke="#3BA5F3"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15 3C16.95 8.84 16.95 15.16 15 21"
                stroke="#3BA5F3"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3 9.0001C8.84 7.0501 15.16 7.0501 21 9.0001"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        ),
        title: "Worldwide Presence",
        desc: "Backpac's global distribution of RPC requests ensures peak performance for users across the globe.",
      },
      {
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#1D476D"
            className="w-8 h-8"
          >
            <g
              id="页面-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Development"
                transform="translate(-864.000000, 0.000000)"
                fillRule="nonzero"
              >
                <g
                  id="performance_line"
                  transform="translate(864.000000, 0.000000)"
                >
                  <path
                    d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                    id="MingCute"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M19,3 C20.1046,3 21,3.89543 21,5 L21,19 C21,20.1046 20.1046,21 19,21 L5,21 C3.89543,21 3,20.1046 3,19 L3,5 C3,3.89543 3.89543,3 5,3 L19,3 Z M19,5 L5,5 L5,19 L19,19 L19,5 Z M12.0371,7.72855 C12.3082739,6.86097522 13.4777299,6.77813919 13.8878385,7.54487333 L13.9389,7.6547 L15.677,12 L17,12 C17.5523,12 18,12.4477 18,13 C18,13.51285 17.613973,13.9355092 17.1166239,13.9932725 L17,14 L15.0068,14 C14.6351,14 14.297096,13.796366 14.1213836,13.4760677 L14.069,13.3651 L13.1158,10.9821 L11.4619,16.2746 C11.2030273,17.1027636 10.1072568,17.2277874 9.65460915,16.5302559 L9.59448,16.425 L8.38197,14 L7,14 C6.44772,14 6,13.5523 6,13 C6,12.48715 6.38604429,12.0644908 6.88337975,12.0067275 L7,12 L8.99382,12 C9.33388222,12 9.64798889,12.1707457 9.83374702,12.4490272 L9.89719,12.5583 L10.2853,13.3345 L12.0371,7.72855 Z"
                    id="形状"
                    fill="#1D476D"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        ),
        title: "Low Latency and High Performance",
        desc: "Backpac ensures high uptime and ultra-low latency for uninterrupted and swift blockchain access.",
      },
      {
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#1D476D"
            className="w-8 h-8"
          >
<path id="servers_1_" d="M23,31.36H9c-0.199,0-0.36-0.161-0.36-0.36v-3.64H1c-0.199,0-0.36-0.161-0.36-0.36V5
	c0-0.199,0.161-0.36,0.36-0.36h7.64V1c0-0.199,0.161-0.36,0.36-0.36h14c0.199,0,0.36,0.161,0.36,0.36v3.64H31
	c0.199,0,0.36,0.161,0.36,0.36v22c0,0.199-0.161,0.36-0.36,0.36h-7.64V31C23.36,31.199,23.199,31.36,23,31.36z M9.36,30.64h13.28
	V1.36H9.36V30.64z M23.36,26.64h7.279V5.36H23.36v2.28H27v0.72h-3.64v2.28H27v0.72h-3.64v2.28H27v0.72h-3.64V26.64z M1.36,26.64
	h7.28V14.36H5v-0.72h3.64v-2.28H5v-0.72h3.64V8.36H5V7.64h3.64V5.36H1.36V26.64z M20,10.36h-8V9.64h8V10.36z M20,7.36h-8V6.64h8
	V7.36z M20,4.36h-8V3.64h8V4.36z"/>
<rect id="_Transparent_Rectangle" style={{fill:'none'}} width="32" height="32"/>
          </svg>
        ),
        title: "Reliability and Redundancy",
        desc: "Experience minimal downtime with automatic routing to active endpoints, enhancing the reliability of your RPC services.",
      },
      {
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#1D476D"
            className="w-8 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
            />
          </svg>
        ),
        title: "Leading RPC Node Providers",
        desc: "Choose from a variety of trusted and reputable providers, such as QuickNode and others, seamlessly integrated for your ease of access.",
      },
      {
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#1D476D"
            className="w-8 h-8"
          >
            <path d="M17 16v1h-17v-1h17zM5.203 7.976l4.204 3.026 5.593-6.251v2.284h1v-4.035h-4.036v1h2.366l-5.070 5.665-4.129-2.974-4.372 3.956 0.671 0.741 3.773-3.412z" fill="#3BA5F3" />
          </svg>
        ),
        title: "Increased Rate Limits",
        desc: "Combine your RPC endpoints for increased throughput and flexibility, with custom routing of RPC methods to meet your specific requirements.",
      },
    ];

    return (
      <section className="py-14">
        <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
          <div className="relative max-w-2xl mx-auto sm:text-center">
            <div className="relative z-10">
              <h3 className="font-semibold text-neutral-900 dark:text-neutral-100 text-3xl xl:text-4xl">
                <span style={{ color: "#1D476D" }}>Back</span>
                <span style={{ color: "#3BA5F3" }}>pac's</span> Web3 RPC Load Balancer
              </h3>
              <p className="mt-3 text-neutral-700 dark:text-neutral-300">
                We believe in simplifying complexity and streamlining user
                experience. With multiple RPC node providers at your fingertips,
                we consolidate your RPC endpoints into a single RPC endpoint.
              </p>
            </div>
            <div
              className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]"
              style={{
                background:
                  "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)",
              }}
            ></div>
          </div>
          <div className="relative mt-12">
            <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((item, idx) => (
                <li
                  key={idx}
                  className="bg-white space-y-3 p-4 border rounded-lg"
                >
                  <div className="text-indigo-600 pb-3">{item.icon}</div>
                  <h4 className="text-lg text-gray-800 font-semibold">
                    {item.title}
                  </h4>
                  <p>{item.desc}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    );
}
export default Plus;