//import Flow from "./flow/Flow";
import { useEffect  } from "react";
import { Helmet } from "react-helmet";
import Hero from "./sections/Hero";
import QuickNode from "containers/PageHome/sections/Quicknode";
import Plans from "./sections/Plans";
import Benefits from "./sections/Benefits";
import Ecosystem from "./sections/Ecosystem";
import Enterprise from "./sections/Enterprise";
//import Future from "./sections/Future";
import Next from "./sections/Next";
import CustomGateway from "./sections/CustomGateway";
const Arweave = () => {
  useEffect(() => {


  }, []);


  return (
    <>
      <Helmet>
        <title>Arweave Gateway | Gateway to the PermaWeb</title>
        <meta
          name="description"
          content={
            "Discover the Permaweb with Backpac's Arweave Gateway, offering seamless exploration. Query information on Arweave, as Backpac facilitates data retrieval and caching, enhancing the scalability of the network."
          }
        ></meta>
        <meta
          name="keywords"
          content={
            "Gateways, Permaweb, Arweave, Infrastructure Utilities, Data Retrieval, Caching, Serving, Transaction Indexing, Arweave Mining Nodes, Layer 1 Blockweave, Global Data Ownership, Permanent Web Pages, Zero Maintenance"
          }
        ></meta>
        <meta
          name="twitter:image"
          content="https://www.backpac.xyz/static/media/Arweave.d573b66474bd5f6e797e.png"
        />
        <meta
          name="twitter:image:alt"
          content="Arweave Gateway"
        />
        <meta
          property="og:image"
          content="https://www.backpac.xyz/static/media/Arweave.d573b66474bd5f6e797e.png"
        />
        <meta
          property="og:image:alt"
          content="Arweave Gateway"
        />
      </Helmet>
      <Hero />
      <Benefits />
      <Next />
      <Plans />
      <CustomGateway />
      <Enterprise />
      <Ecosystem />
      <QuickNode />
    </>
  );
};
export default Arweave;
