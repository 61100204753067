
import BackpacIcon from "images/backpac-icon.svg";
import { Tooltip } from 'react-tooltip';
import { Blockchains } from "interfaces/Blockchains";

interface RPCProvidersProps {
  blockchain: string;
}
  
 const RPCProviders: React.FC<RPCProvidersProps> = ({ blockchain }) => {
    function capitalize(word: string): string {
      if (!word) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    }

    return (
      <div className="bg-white dark:bg-neutral-900 py-24 sm:py-32">
        <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
          <div className="max-w-2xl">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
            {capitalize(blockchain)} RPC Node Providers
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-neutral-400">
              Explore our curated list of trusted {capitalize(blockchain)} RPC node providers –
              the gateway to the {capitalize(blockchain)} blockchain network.
            </p>
          </div>
          <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
            {Blockchains[blockchain].map((chain) => (
              <li key={chain.name}>
                <div className="flex items-center gap-x-6">
                  {typeof chain.addOnHref !== "undefined" ? (
                    <img
                      className="h-16 w-16 rounded-full"
                      src={chain.imageUrl}
                      alt={`${capitalize(blockchain)} RPC Node Provider ${chain.name}`}
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={'QuickNode is a Backpac Partner'}
                    />
                  ) : (
                    <img
                      className="h-16 w-16 rounded-full"
                      src={chain.imageUrl}
                      alt={"Solana RPC Node Provider " + chain.name}
                    />
                  )}
                  <div>
                    <h3 className="text-base font-semibold  text-gray-900 dark:text-white">
                      {chain.name}
                      {typeof chain.addOnHref !== "undefined" && (
                        <span
                         className="dark:display:none"
                          style={{
                            display: "inline-block",
                            verticalAlign: "top",
                            marginLeft: "8px",
                          }}
                        >
                          <a
                            href={chain.addOnHref}
                            target="_new"
                            className="text font-semibold leading-6 text-gray-900 dark:text-neutral-400"
                          >
                            <img
                              className="h-5 w-5 rounded-full"
                              src={BackpacIcon}
                              alt={chain.addOnText}
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={chain.addOnText}
                            />
                          </a>
                        </span>
                      )}
                    </h3>
                    <p className="text-sm font-semibold leading-6 text-indigo-600 dark:text-neutral-400">
                      <a
                        href={chain.href}
                        target="_new"
                        className="text font-semibold leading-6 text-gray-900 dark:text-neutral-400"
                      >
                        {chain.role}
                        <span aria-hidden="true">→</span>
                      </a>
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <Tooltip id="my-tooltip" />
        </div>
      </div>
    );
}
export default RPCProviders  