import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import QuickNode from "./Providers/QuickNode";
import Chainstack from "./Providers/Chainstack";
import ProductFeatures from "./Features";
import ProductHeader from "./ProductHeader";
export interface ProductProps {
  className?: string;
}

const Product: FC<ProductProps> = ({ className = "" }) => {
  const params = useParams();
  const [provider, setProvider] = useState<string>(""); 
  const [plan, setPlan] = useState<string>(""); 

  function renderProviderComponent(provider: string) {
    let component;
    switch (provider) {
      case "quicknode":
        component = <QuickNode provider={provider} featured={false} />;
        break;
      default:
        component = <Chainstack provider={provider} featured={false} />;
        break;
    }
    return component;
  }

  useEffect(() => {
    if (params !== null) {
      console.log(params);
      if(typeof params.provider !== "undefined"){
        setProvider(params.provider)
      }

      if(typeof params.plan !== "undefined"){
        setPlan(params.plan)
      }
    }
  }, [params]);

  return (
    <>
      <Helmet>
        <title>
          {provider} Backpac Integration | {plan} plan
        </title>
        <meta
          name="description"
          content={
            "Backpac boosts throughput of RPC requests across blockchain networks, with user-friendly fault tolerant RPC infrastructure."
          }
        ></meta>
        <meta
          name="keywords"
          content={
            "integration, apps, rpc node, rpc node providers, node operators, throughput, across Blockchain Networks, Web3 Provider Redundancy, Smart RPC Load Balancer, Blockchain RPC Connectivity, Multi-RPC Provider, Blockchain Access, Reliable RPC Service, Web3 Companies, Ultra-Fast Responsiveness, Bulletproof Reliability, Seamless Scaling, RPC Load Balancer, Top RPC Providers, Custom RPC Providers, Ethereum, Coinbase, Polygon, Solana, Binance Smart Chain, Enterprise Plans"
          }
        ></meta>
      </Helmet>
      <ProductHeader provider={provider} plan={plan} />
       {renderProviderComponent(provider)}
      <ProductFeatures />
    </>
  );
};
export default Product;