import { FC, useState, useEffect  } from "react";
import {  useSearchParams } from 'react-router-dom';
// import facebookSvg from "images/Facebook.svg";
// import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import Logo from "shared/Logo/LogoWithProps";
//import { RadioGroup } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import Auth0 from "model/Auth0";
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import SignUpModal from "./Modal";

var passwordValidator = require("password-validator");
let schema = new passwordValidator();

//import Radio from "shared/Radio/Radio";
export interface PageSignUpProps {
  className?: string;
}

const toastConfig: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
}




const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const { loginWithRedirect } = useAuth0();
  const [search] = useSearchParams();
  const [email, setEmail] = useState<string>('');
  const [pass, setPass] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [plan, setPlan] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [organizationName, setOrganizationName] = useState<string>('');
  const [open, setOpen] = useState(false);



  useEffect(() => {
    if (search.get("plan") !== null) {
      setPlan(search.get("plan") || "")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function isValidEmail(email: string): boolean {
    // Regular expression for a basic email format validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
    return emailRegex.test(email);
  }
  const loginSocials = [
    {
      name: "Continue with Google",
      href: "/",
      icon: googleSvg,
      onclick: async (event: any) => {
        try {
          loginWithRedirect({
            authorizationParams: {
              //organization: "org_YPArZneuZCliQS45",
            },
          });
          //event.preventDefault();
          //await socialLogin();
        } catch (error) {
          console.error(error);
        }
      }
    },
  ];

  const handleCreate = async () => {
    //We need auth0
    const _auth0 = new Auth0();

    //Create an ORG object
    const account = {
      ORG_NAME: "",
      ORG_DISPLAY_NAME: "",
      admin: {
        email,
        password: pass,
        name
      },
      metadata: {
        plan: ""
      }
    };


    //We need a company name slug
    //Strip special characters
    account.ORG_NAME = organizationName.replace(/[^\w\s]/g, "");
    //Lowercase the name
    account.ORG_NAME = organizationName.toLowerCase();
    //Replace any spaces with a hypen
    account.ORG_NAME = account.ORG_NAME.replace(/ /g, "-");

    //Details
    account.ORG_DISPLAY_NAME = organizationName.replace(/[^\w\s]/g, "");

    //Check if a plan was specified
    if (typeof plan !== "undefined") {
      account.metadata.plan = plan;
    }

    //Create the company
    console.log(account);

    const auth0UserAndAccount = await _auth0.createUserAndAccount(account);

    console.log(auth0UserAndAccount);

    return false;
  }

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();
      setLoading(true);
      setOpen(true);
      toastConfig.toastId = "handleSubmit";

      if(name === ""){
        
        toast.warn("Please provide your name..", toastConfig);
        setLoading(false);
        return false;
      };

      if(organizationName === ""){
        toast.warn("Please provide your Organizations Name..", toastConfig);
        setLoading(false);
        return false;
      };

      if(email === ""){
        toast.warn("Please provide an email address..", toastConfig);
        setLoading(false);
        return false;
      };

      if(isValidEmail(email) === false){
        toast.warn("Please provide a valid email address..", toastConfig);
        setLoading(false);
        return false;
      }

      if(pass === ""){
        toast.warn("Please provide a password..", toastConfig);
        setLoading(false);
        return false;
      };

      
      toast.update(toastConfig.toastId, { render: "Checking Password", type: "info", isLoading: true });

      


      schema
        .is()
        .min(6) // Minimum length 8
        .is()
        .max(16) // Maximum length 16
        .has()
        .uppercase() // Must have uppercase letters
        .has()
        .lowercase() // Must have lowercase letters
        .has()
        .digits() // Must have digits
        .has()
        .not()
        .spaces() // Should not have spaces
        .is()
        .not()
        .oneOf(["Passw0rd", "Password123"]);

      if (schema.validate(pass) === false) {
        toast.update(toastConfig.toastId, {
          render:
            "Password must be at least 8 characters (Max 16). Must have uppercase letters. Must have lowercase letters. Must have digits",
          type: "error",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          isLoading: false,
          theme: "colored",
        });
        setLoading(false);
        return false;
      }


  
      toast.promise(handleCreate, {
        pending: {
          render(){
            return `Validating Invitation to`
          },
          icon: false,
          isLoading: true,
          toastId: toastConfig.toastId
        },
        success: {
          render({data}){
            if (typeof data !== "undefined") {
              console.log('data', data)
              setLoading(false);
              return "Invitation Validated. Please enter a password for your account.";
            }else{
              setLoading(false);
              return "Invitation not valid";
            }
          },
          // other options
          icon: "🟢",
          position: "top-right",
          autoClose: 7500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          isLoading: false,
          theme: "colored",
          toastId: toastConfig.toastId
        },
        error: {
          render({data}){
            // When the promise reject, data will contains the error
            setLoading(false);
            console.error(data);
            return "Invitation Invalid";
          },
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          isLoading: false,
          toastId: toastConfig.toastId
        }
      });


    } catch (error) {
      console.error(error);
    }
  };

  // const [typeSelected, setTypeSelected] = useState({name: "individual", id: "individual", label: "Individual"});


  // const renderCheckIcon = () => {
  //   return (
  //     <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
  //       <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
  //       <path
  //         d="M7 13l3 3 7-7"
  //         stroke="#fff"
  //         strokeWidth={1.5}
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //     </svg>
  //   );
  // };


  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up for Reliable blockchain access || Backpac Inc</title>
      </Helmet>
      <div
        className="flex justify-center flex-grow items-center space-x-3 sm:space-x-8 lg:space-x-10"
        style={{ marginBottom: "25px" }}
      >
        <Logo height="70" width="70" />
      </div>
      <div className="container mb-24 lg:mb-32">
        <SignUpModal state={open}/>
        <div className="max-w-md mx-auto space-y-6">
          <h1 className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            Sign up for an account
          </h1>

          {/* <div className="relative flex flex-col px-5 py-6 space-y-3">
            <RadioGroup value={typeSelected} onChange={setTypeSelected}>
              <RadioGroup.Label className="sr-only">
                Customer Type
              </RadioGroup.Label>
              <div className="grid grid-cols-2 lg:grid-cols-2 gap-2">
                <RadioGroup.Option
                  key={"individual"}
                  value={{
                    name: "individual",
                    id: "individual",
                    label: "Individual",
                  }}
                  className={({ checked }) => {
                    return `${
                      typeSelected.id === "individual"
                        ? "bg-primary-6000 text-white text-bold dark:bg-primary-700"
                        : "bg-white dark:bg-black/20 border-t dark:border-0 border-neutral-50 "
                    } relative shadow-lg rounded-lg px-3 py-3 cursor-pointer flex sm:px-5 sm:py-4 focus:outline-none `;
                  }}
                >
                  {({ checked }) => (
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium line-clamp-1 ${
                              checked
                                ? "text-white"
                                : "text-neutral-900 dark:text-white"
                            }`}
                          >
                            Individual Account
                          </RadioGroup.Label>
                        </div>
                      </div>
                      {checked && (
                        <div className="flex-shrink-0 text-white">
                          {renderCheckIcon()}
                        </div>
                      )}
                    </div>
                  )}
                </RadioGroup.Option>

                <RadioGroup.Option
                  key={"organization"}
                  value={{
                    name: "organization",
                    id: "organization",
                    label: "Business",
                  }}
                  className={({ checked }) => {
                    return `${
                      typeSelected.id === "organization"
                        ? "bg-primary-6000 text-white text-bold dark:bg-primary-700"
                        : "bg-white dark:bg-black/20 border-t dark:border-0 border-neutral-50 "
                    } relative shadow-lg rounded-lg px-3 py-3 cursor-pointer flex sm:px-5 sm:py-4 focus:outline-none `;
                  }}
                >
                  {({ checked }) => (
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium line-clamp-1 ${
                              checked
                                ? "text-white"
                                : "text-neutral-900 dark:text-white"
                            }`}
                          >
                            Business Account
                          </RadioGroup.Label>
                        </div>
                      </div>
                      {checked && (
                        <div className="flex-shrink-0 text-white">
                          {renderCheckIcon()}
                        </div>
                      )}
                    </div>
                  )}
                </RadioGroup.Option>
              </div>
            </RadioGroup>
          </div> */}
          {/* <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
            Sign up for an account
              </span> */}
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                onClick={item.onclick}
                className=" flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>

          {/* FORM */}
          <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-6" action="#" method="post">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Full name
              </span>
              <Input
                name="name"
                type="text"
                placeholder="John Doe"
                className="mt-1"
                disabled={loading}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Organization name
              </span>
              <Input
                name="organization"
                type="text"
                placeholder="ABC Inc"
                className="mt-1"
                disabled={loading}
                value={organizationName}
                onChange={(e) => {
                  setOrganizationName(e.target.value);
                }}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                name="email"
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                disabled={loading}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input
                name="pwd"
                type="password"
                className="mt-1"
                disabled={loading}
                value={pass}
                onChange={(e) => {
                  setPass(e.target.value);
                }}
              />
            </label>
            <ButtonPrimary type="submit" disabled={loading}>
              Continue
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login" className="text-green-600">
              Sign in
            </Link>
          </span>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PageSignUp;
