import { useEffect, useState } from "react"
import { BuilderComponent, builder,} from '@builder.io/react'
import { Helmet } from "react-helmet";
builder.init(typeof process.env.REACT_APP_ACCESS_BUILDER_IO === "undefined" ? "" : process.env.REACT_APP_ACCESS_BUILDER_IO);
    
  export const BuilderIO = () => {
  
    const [builderContentJson, setBuilderContentJson] = useState({data: {title: "", keywords: "", description: ""}})
  
    useEffect(() => { 
      builder.get('page', { url: window.location.pathname })
        .promise().then(setBuilderContentJson)
    }, [])
  
    return (
        <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>{builderContentJson.data.title}</title>
        <meta name="description" content={builderContentJson.data.description}></meta>
        <meta name="keywords" content={builderContentJson.data.keywords}></meta>
      </Helmet>
      <BuilderComponent model="page" content={builderContentJson} />
    </div>
    )
  }