import { useState, useEffect } from "react";
import { PopupButton } from '@typeform/embed-react';
import { CheckIcon } from "@heroicons/react/24/solid"
import StripeUtils from "model/StripeUtils";
import { StripeProduct } from "interfaces/StripeProduct";

export default function Enterprise() {
  const domain = window.location.origin;
  const [products, setProducts] = useState<Array<any>>([]);
  useEffect(() => {
    const getProduct = async () => {
    //setLoading(true);

      //Setup the Stripe object
      const _StripeUtils = new StripeUtils(domain, undefined);

      const _products: Array<StripeProduct> = await _StripeUtils.retrieveProducts();
      //console.log(_products);
      setProducts(_products);
      //Set the data for display
      //setProduct(product);

      //Stop loading
      //setLoading(false);

      //Show the UI
      //setInit(false);
    };
    //Grab the products and pricing data for the UI
    getProduct();
  }, [domain]);

  return (
    <div className="bg-white py-8 sm:py-16">
      {products.map((product) => (
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3
                className="text-2xl font-bold tracking-tight"
                style={{ color: "#1D476D" }}
              >
                {product.metadata.plan} Plan
              </h3>
              <p className="mt-6 text-base leading-7 text-gray-600">
               {product.metadata.description}
              </p>
              <div className="mt-10 flex items-center gap-x-4">
                <h4
                  className="flex-none text-sm font-semibold leading-6"
                  style={{ color: "#1D476D" }}
                >
                  What’s included
                </h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>
              <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                {product.features.map((feature: any, i: number) => {
                  let key = feature.name.replace(/  +| /g, '-').replace('/','');
                  key = key + '-' + product.unit_label;
                  console.log(key)
                  return (
                    <li key={key.toLowerCase()} className="flex gap-x-3">
                      <CheckIcon
                        color="#3BA5F3"
                        className="h-6 w-5 flex-none"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div className="mx-auto max-w-xs px-8">
                  <p className="text-base font-semibold text-gray-600">
                    Simple pricing model
                  </p>
                  <p className="mt-6 flex items-baseline justify-center gap-x-2">
                    <span className="text-5xl font-bold tracking-tight text-gray-900">
                    {product.metadata.ratelimit}
                    </span>
                    <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                      R/S
                    </span>
                  </p>

                  <PopupButton
                    id={"GUMgTayZ"}
                    style={{ backgroundColor: "#1D476D" }}
                    className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    size={66}
                    medium="demo-test"
                    hidden={{ foo: "foo value", bar: "bar value" }}
                  >
                    <span style={{ marginLeft: 10 }}>Create API Keys</span>
                  </PopupButton>

                  {/* <a
                  href="/"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Create API Keys
                </a> */}
                  <p className="mt-6 text-xs leading-5 text-gray-600">
                    {product.statement_descriptor}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
