import gatewayIcon from "images/icons/internet-51-512.png";

export default function CustomGateway() {
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="container mx-auto max-w-7xl px-6 lg:px-8">
        <div className="lg:flex lg:items-center">
          <div className="w-full space-y-12 lg:w-1/2 ">
            <div>
              <h1 className="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl dark:text-white">
                explore Custom
                <br /> Arweave Gateways
              </h1>

              <div className="mt-2">
                <span className="inline-block w-40 h-1 rounded-full bg-blue-500"></span>
                <span className="inline-block w-3 h-1 ml-1 rounded-full bg-blue-500"></span>
                <span className="inline-block w-1 h-1 ml-1 rounded-full bg-blue-500"></span>
              </div>
            </div>

            <div className="md:flex md:items-start md:-mx-4">
              <span className="inline-block p-2 text-blue-500 bg-white rounded-xl md:mx-4 dark:text-white dark:white">
                <svg
                  className="svg-icon w-8 h-8"
                  style={{
                    verticalAlign: "middle",
                    fill: "currentColor",
                    overflow: "hidden",
                  }}
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M982.486486 985.254054H574.962162c-12.454054 0-22.140541-9.686486-22.14054-22.14054 0-12.454054 9.686486-22.140541 22.14054-22.140541h407.524324c12.454054 0 22.140541 9.686486 22.140541 22.140541 0 11.762162-9.686486 22.140541-22.140541 22.14054zM514.075676 314.810811c-12.454054 0-22.140541-13.145946-22.140541-29.05946V217.945946c0-15.913514 9.686486-29.059459 22.140541-29.05946C526.52973 188.886486 536.216216 202.032432 536.216216 217.945946v67.805405c0 15.913514-9.686486 29.059459-22.14054 29.05946zM514.075676 602.637838c-12.454054 0-22.140541-16.605405-22.140541-36.67027V491.243243c0-20.064865 9.686486-36.67027 22.140541-36.67027 12.454054 0 22.140541 16.605405 22.14054 36.67027v74.724325c0 20.064865-9.686486 36.67027-22.14054 36.67027zM453.189189 985.254054H44.972973c-12.454054 0-22.140541-9.686486-22.140541-22.14054 0-12.454054 9.686486-22.140541 22.140541-22.140541h408.216216c12.454054 0 22.140541 9.686486 22.140541 22.140541 0 11.762162-9.686486 22.140541-22.140541 22.14054zM514.075676 923.675676c-12.454054 0-22.140541-9.686486-22.140541-22.140541V765.232432c0-12.454054 9.686486-22.140541 22.140541-22.14054 12.454054 0 22.140541 9.686486 22.14054 22.14054v136.302703c0 12.454054-9.686486 22.140541-22.14054 22.140541z"
                    fill="#3973F4"
                  />
                  <path
                    d="M514.075676 963.113514m-60.886487 0a60.886486 60.886486 0 1 0 121.772973 0 60.886486 60.886486 0 1 0-121.772973 0Z"
                    fill="#C5DCFA"
                  />
                  <path
                    d="M944.432432 224.864865H83.718919c-18.681081 0-33.902703-15.221622-33.902703-33.902703V35.978378C49.816216 17.297297 65.037838 2.075676 83.718919 2.075676H944.432432c18.681081 0 33.902703 15.221622 33.902703 33.902702v154.983784c0 18.681081-15.221622 33.902703-33.902703 33.902703z"
                    fill="#4988FD"
                  />
                  <path
                    d="M128.691892 119.005405h29.059459M157.751351 132.151351h-29.059459c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946h29.059459c7.610811 0 13.145946 6.227027 13.145946 13.145946 0 6.918919-6.227027 13.145946-13.145946 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M208.259459 119.005405h28.367568M236.627027 132.151351h-29.059459c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946h29.059459c7.610811 0 13.145946 6.227027 13.145946 13.145946 0 6.918919-5.535135 13.145946-13.145946 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M287.135135 119.005405h29.05946M316.194595 132.151351H287.135135c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946h29.05946c7.610811 0 13.145946 6.227027 13.145946 13.145946 0 6.918919-6.227027 13.145946-13.145946 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M366.702703 119.005405h28.367567M395.07027 132.151351h-29.059459c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946h29.059459c7.610811 0 13.145946 6.227027 13.145946 13.145946 0 6.918919-5.535135 13.145946-13.145946 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M445.578378 119.005405h29.05946M474.637838 132.151351h-29.05946c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946h29.05946c7.610811 0 13.145946 6.227027 13.145946 13.145946 0 6.918919-6.227027 13.145946-13.145946 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M525.145946 119.005405h28.367568M553.513514 132.151351h-29.05946c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946h29.05946c7.610811 0 13.145946 6.227027 13.145945 13.145946 0 6.918919-5.535135 13.145946-13.145945 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M604.021622 119.005405H633.081081M633.081081 132.151351h-29.059459c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946H633.081081c7.610811 0 13.145946 6.227027 13.145946 13.145946 0 6.918919-6.227027 13.145946-13.145946 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M860.021622 119.005405m-49.816217 0a49.816216 49.816216 0 1 0 99.632433 0 49.816216 49.816216 0 1 0-99.632433 0Z"
                    fill="#3973F4"
                  />
                  <path
                    d="M944.432432 501.621622H83.718919c-18.681081 0-33.902703-15.221622-33.902703-33.902703V312.735135C49.816216 294.054054 65.037838 278.832432 83.718919 278.832432H944.432432c18.681081 0 33.902703 15.221622 33.902703 33.902703v154.983784c0 18.681081-15.221622 33.902703-33.902703 33.902703z"
                    fill="#4988FD"
                  />
                  <path
                    d="M128.691892 395.762162h29.059459M157.751351 408.908108h-29.059459c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946h29.059459c7.610811 0 13.145946 6.227027 13.145946 13.145946 0 6.918919-6.227027 13.145946-13.145946 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M208.259459 395.762162h28.367568M236.627027 408.908108h-29.059459c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946h29.059459c7.610811 0 13.145946 6.227027 13.145946 13.145946 0 6.918919-5.535135 13.145946-13.145946 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M287.135135 395.762162h29.05946M316.194595 408.908108H287.135135c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946h29.05946c7.610811 0 13.145946 6.227027 13.145946 13.145946 0 6.918919-6.227027 13.145946-13.145946 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M366.702703 395.762162h28.367567M395.07027 408.908108h-29.059459c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946h29.059459c7.610811 0 13.145946 6.227027 13.145946 13.145946 0 6.918919-5.535135 13.145946-13.145946 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M445.578378 395.762162h29.05946M474.637838 408.908108h-29.05946c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946h29.05946c7.610811 0 13.145946 6.227027 13.145946 13.145946 0 6.918919-6.227027 13.145946-13.145946 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M525.145946 395.762162h28.367568M553.513514 408.908108h-29.05946c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946h29.05946c7.610811 0 13.145946 6.227027 13.145945 13.145946 0 6.918919-5.535135 13.145946-13.145945 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M604.021622 395.762162H633.081081M633.081081 408.908108h-29.059459c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946H633.081081c7.610811 0 13.145946 6.227027 13.145946 13.145946 0 6.918919-6.227027 13.145946-13.145946 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M860.021622 395.762162m-49.816217 0a49.816216 49.816216 0 1 0 99.632433 0 49.816216 49.816216 0 1 0-99.632433 0Z"
                    fill="#3973F4"
                  />
                  <path
                    d="M944.432432 778.378378H83.718919c-18.681081 0-33.902703-15.221622-33.902703-33.902702V589.491892C49.816216 570.810811 65.037838 555.589189 83.718919 555.589189H944.432432c18.681081 0 33.902703 15.221622 33.902703 33.902703v154.983784c0 18.681081-15.221622 33.902703-33.902703 33.902702z"
                    fill="#4988FD"
                  />
                  <path
                    d="M128.691892 672.518919h29.059459M157.751351 685.664865h-29.059459c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946h29.059459c7.610811 0 13.145946 6.227027 13.145946 13.145946 0 6.918919-6.227027 13.145946-13.145946 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M208.259459 672.518919h28.367568M236.627027 685.664865h-29.059459c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946h29.059459c7.610811 0 13.145946 6.227027 13.145946 13.145946 0 6.918919-5.535135 13.145946-13.145946 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M287.135135 672.518919h29.05946M316.194595 685.664865H287.135135c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946h29.05946c7.610811 0 13.145946 6.227027 13.145946 13.145946 0 6.918919-6.227027 13.145946-13.145946 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M366.702703 672.518919h28.367567M395.07027 685.664865h-29.059459c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946h29.059459c7.610811 0 13.145946 6.227027 13.145946 13.145946 0 6.918919-5.535135 13.145946-13.145946 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M445.578378 672.518919h29.05946M474.637838 685.664865h-29.05946c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946h29.05946c7.610811 0 13.145946 6.227027 13.145946 13.145946 0 6.918919-6.227027 13.145946-13.145946 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M525.145946 672.518919h28.367568M553.513514 685.664865h-29.05946c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946h29.05946c7.610811 0 13.145946 6.227027 13.145945 13.145946 0 6.918919-5.535135 13.145946-13.145945 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M604.021622 672.518919H633.081081M633.081081 685.664865h-29.059459c-7.610811 0-13.145946-6.227027-13.145946-13.145946 0-6.918919 6.227027-13.145946 13.145946-13.145946H633.081081c7.610811 0 13.145946 6.227027 13.145946 13.145946 0 6.918919-6.227027 13.145946-13.145946 13.145946z"
                    fill="#3973F4"
                  />
                  <path
                    d="M860.021622 672.518919m-49.816217 0a49.816216 49.816216 0 1 0 99.632433 0 49.816216 49.816216 0 1 0-99.632433 0Z"
                    fill="#3973F4"
                  />
                </svg>
              </span>

              <div className="mt-4 md:mx-4 md:mt-0">
                <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white">
                  Custom Arweave Nodes
                </h1>

                <p className="mt-3 text-gray-500 dark:text-gray-300">
                  Tailor your decentralized experience, ensuring optimal
                  reliability and efficiency for accessing the Permaweb's vast
                  and dynamic content.
                </p>
              </div>
            </div>

            <div className="md:flex md:items-start md:-mx-4">
              <span className="inline-block p-2 text-blue-500 bg-white rounded-xl md:mx-4 dark:text-white dark:bg-white">
                <svg
                  className="svg-icon w-8 h-8"
                  style={{
                    verticalAlign: "middle",
                    fill: "currentColor",
                    overflow: "hidden",
                  }}
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M131.584 805.376l320.512 98.816c13.312 4.096 27.136 6.144 41.472 6.144 13.824 0 27.648-2.048 41.472-6.144l320.512-98.816c26.112-7.68 44.032-32.768 43.52-59.392v-110.08c0-12.8-10.752-23.552-23.552-23.552h-75.264c-12.8 0-23.552 10.752-23.552 23.552v64L555.008 768V257.536l221.696 68.096v99.84h-16.384c-9.216 0-17.408 5.12-21.504 13.312-4.096 8.192-2.56 17.92 3.072 25.088l77.312 93.696a23.552 23.552 0 0 0 18.432 8.704c7.168 0 13.824-3.584 18.432-9.216l74.24-93.696c5.632-7.168 6.656-16.896 2.56-25.088-4.096-8.192-12.288-13.312-21.504-13.312h-13.312V280.064c0-27.136-17.408-51.2-43.52-59.392L520.704 117.76c-18.432-5.12-37.888-5.12-56.32 0L332.8 158.72c-15.872 5.12-26.624 19.456-26.624 36.352v167.424l-37.888 7.168V229.888c0-11.776-5.632-23.552-15.36-30.72-9.728-7.168-22.528-9.216-33.792-5.632L134.144 220.16c-27.136 7.168-46.08 31.744-46.08 59.904v465.408c0 27.648 17.92 52.224 43.52 59.904z m4.096-525.312c0-6.656 4.608-12.288 11.776-14.336l74.752-23.04v138.24c0 11.264 5.12 22.016 13.824 29.184 8.704 7.168 20.48 10.24 31.232 8.192l56.32-10.752c17.92-3.584 30.72-18.944 30.72-37.376V201.728l124.416-38.912c9.728-3.072 19.968-3.072 29.184 0l332.8 102.912c6.144 2.048 10.752 7.68 10.752 14.336v169.984c0 9.216 5.632 17.408 13.312 21.504l-27.648 34.816-28.16-34.304c8.704-3.584 14.848-11.776 14.848-22.016V308.736c0-10.24-6.656-19.456-16.896-22.528l-268.8-82.944c-7.168-2.048-14.848-1.024-20.992 3.584-6.144 4.608-9.728 11.264-9.728 18.944v574.464c0 7.68 3.584 14.336 9.728 18.944 6.144 4.608 13.824 5.632 20.992 3.584l268.8-82.944c9.728-3.072 16.896-12.288 16.896-22.528v-57.856h27.648v87.04c0 6.144-4.096 11.776-10.24 13.824l-320.512 98.816c-17.92 5.632-36.864 5.632-54.784 0l-320.512-98.816c-6.144-2.048-10.24-7.68-10.24-14.336v-465.92z"
                    fill="#ACBEC9"
                  />
                  <path
                    d="M292.352 654.336c7.68 0.512 15.872-2.56 22.016-8.704 8.704-8.192 13.312-20.48 13.312-33.792s-5.12-25.6-13.312-33.792c-6.144-5.632-13.312-8.704-20.992-8.704h-1.024c-18.432 1.024-33.28 19.968-33.28 42.496s14.848 41.984 33.28 42.496z"
                    fill="#EB5F9D"
                  />
                  <path
                    d="M191.488 649.216c-1.536 2.56-1.536 6.656-0.512 9.728 3.072 7.168 4.608 11.264 6.656 15.36 1.536 4.096 3.584 8.192 6.656 15.36 1.536 3.072 4.096 5.12 6.656 5.12l29.696-1.024c4.608 3.584 7.68 6.656 12.288 10.24l12.8 37.888c1.536 3.584 4.096 6.656 7.68 7.168 15.36 2.56 16.896 2.56 32.768 5.632 3.584 0.512 6.656-1.536 8.192-5.12l15.36-36.864c2.56-1.536 5.12-2.56 7.168-4.096 2.56-1.536 4.608-2.56 7.68-4.096l40.96 9.216c4.096 1.024 8.192-1.024 10.24-4.608 6.656-11.264 9.216-15.872 13.312-23.04 2.048-3.584 4.608-7.68 7.68-13.312 2.048-4.096 2.048-8.704-0.512-12.8l-26.112-38.4v-20.48l26.112-38.4c2.56-4.096 3.072-8.704 0.512-12.8-5.12-8.704-7.68-13.312-10.752-18.432-2.56-4.608-5.632-9.728-10.24-17.92-2.048-3.584-6.144-5.632-10.24-4.608l-40.96 9.216c-5.632-3.072-9.728-5.12-14.848-8.192l-14.848-36.864c-1.536-3.584-4.608-5.632-8.192-5.12-15.872 2.56-17.408 3.072-32.768 5.12-3.072 0.512-6.144 3.584-7.68 7.168l-13.312 37.888c-2.048 1.536-4.096 3.584-6.144 5.12-2.048 1.536-4.096 3.072-6.144 5.12l-29.696-1.024c-2.56 0-5.632 2.048-6.656 5.12-4.096 9.728-5.632 13.824-8.192 19.968-1.024 3.072-2.56 6.144-4.608 11.264-1.536 3.072-1.024 7.168 0.512 9.728l16.384 29.184v16.384l-16.896 30.208z m99.84-103.936c14.336-0.512 28.672 4.608 39.424 15.36 13.312 12.8 20.992 31.232 20.992 51.2 0 19.968-7.68 38.4-20.992 51.2-10.24 9.728-23.552 15.36-37.376 15.36h-2.56c-31.232-1.536-55.808-30.72-55.808-66.56s25.088-65.024 56.32-66.56zM581.12 335.872v363.008c0 9.728 7.68 17.92 17.92 17.92 9.728 0 17.92-7.68 17.92-17.92V335.872c0-9.728-7.68-17.92-17.92-17.92-9.728 0-17.92 7.68-17.92 17.92zM641.024 365.056v304.64c0 9.728 7.68 17.92 17.92 17.92 9.728 0 17.92-7.68 17.92-17.92V365.056c0-9.728-7.68-17.92-17.92-17.92s-17.92 7.68-17.92 17.92zM691.2 385.024v264.192c0 9.728 7.68 17.92 17.92 17.92 9.728 0 17.92-7.68 17.92-17.92V385.024c0-9.728-7.68-17.92-17.92-17.92-9.728 0-17.92 8.192-17.92 17.92z"
                    fill="#EB5F9D"
                  />
                </svg>
              </span>

              <div className="mt-4 md:mx-4 md:mt-0">
                <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white">
                  Custom Throughput
                </h1>

                <p className="mt-3 text-gray-500 dark:text-gray-300">
                  Enjoy swift and efficient access, tailored to meet the demands
                  of your unique requirements for seamless exploration of the
                  Permaweb.
                </p>
              </div>
            </div>

            <div className="md:flex md:items-start md:-mx-4">
              <span className="inline-block p-2 text-blue-500 bg-white rounded-xl md:mx-4 dark:text-white dark:bg-white">
                <svg
                  className="svg-icon h-8 w-8"
                  style={{
                    verticalAlign: "middle",
                    fill: "currentColor",
                    overflow: "hidden",
                  }}
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1024 522.8h-13.6C1010.4 248 786.8 24.8 512 24.8S13.6 248 13.6 522.8H0c0-282.4 229.6-512 512-512s512 229.6 512 512z"
                    fill="#4A555F"
                  />
                  <path
                    d="M993.6 504C983.6 246.8 771.6 40.8 512 40.8S40.4 246.8 30.4 504h963.2z"
                    fill="#FFD552"
                  />
                  <path
                    d="M987.2 529.6h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0H808V516h13.6v13.6z m-27.6 0H780V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0H532V516h13.6v13.6z m-27.6 0H504V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516H380v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0H256V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0h-13.6V516H104v13.6z m-27.6 0h-13.6V516h13.6v13.6z m-27.6 0H35.2V516h13.6v13.6zM844.4 1013.2h-660c-49.6 0-90-40.4-90-90v-343.6l104.8 114.8h645.2c49.6 0 90 40.4 90 90v139.2c0 49.2-40.4 89.6-90 89.6z m-736-398v308c0 42 34 76 76 76h660c42 0 76-34 76-76V784c0-42-34-76-76-76H193.2l-84.8-92.8z"
                    fill="#4A555F"
                  />
                  <path
                    d="M184.4 983.2c-33.2 0-60-26.8-60-60v-266.8L186 724h658.4c33.2 0 60 26.8 60 60v139.2c0 33.2-26.8 60-60 60h-660z"
                    fill="#71CDE1"
                  />
                  <path d="M505.2 17.6h13.6V504h-13.6z" fill="#4A555F" />
                  <path
                    d="M766.4 504h-13.6v-21.6c0-252.4-108-458-240.8-458S271.2 230 271.2 482.4v21.6h-13.6v-21.6c0-125.2 26-243.2 73.2-332 48-90 112.4-139.6 181.2-139.6 68.8 0 133.2 49.6 181.2 139.6 47.2 88.8 73.2 206.8 73.2 332v21.6z"
                    fill="#4A555F"
                  />
                  <path
                    d="M512 347.2c-286.8 0-386.4-144.8-390.4-150.8l11.6-7.6c0.8 1.6 100 144.8 378.8 144.8s378-143.2 378.8-144.8l11.6 7.6c-4 6-103.6 150.8-390.4 150.8zM301.6 908h-12.8l-38.8-104 12.8-4.8 32.4 87.2 25.6-64 12.8 0.4 24 62.4 29.2-85.6 13.2 4.4-35.6 103.6-12.8 0.4-24.4-64zM409.2 894.4h13.6v13.2h-13.6zM488.8 908H476l-38.8-104 12.8-4.8 32.4 87.2 25.6-64 12.8 0.4 24 62.4 29.2-85.6 12.8 4.4-35.2 103.6-12.8 0.4-24.4-64zM676 908h-13.2l-38.4-104 12.8-4.8 32.4 87.2 25.6-64 12.8 0.4 24 62.4 29.2-85.6 12.8 4.4-35.2 103.6-12.8 0.4-24.4-64zM596.8 894.4h13.6v13.2h-13.6z"
                    fill="#4A555F"
                  />
                </svg>
              </span>

              <div className="mt-4 md:mx-4 md:mt-0">
                <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white">
                  Custom Domain
                </h1>

                <p className="mt-3 text-gray-500 dark:text-gray-300">
                  Optimize user experience and reinforce brand identity in the
                  decentralized web. Elevate accessibility with a custom domain
                  for your personalized Arweave gateway, ensuring a seamless and
                  distinctive connection for users.
                </p>
              </div>
            </div>
            <div className="md:flex md:items-start md:-mx-4">
              <span className="inline-block p-2 text-blue-500 bg-white rounded-xl md:mx-4 dark:text-white dark:bg-white">
                <svg
                  className="svg-icon h-8 w-8"
                  style={{
                    verticalAlign: "middle",
                    fill: "currentColor",
                    overflow: "hidden",
                  }}
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M640 240.992l96-42.752V768l-256 92.928-192 66.528v-97.28l-96 33.28v-97.28l-96 33.28V269.76l448-199.552v105.632l96-42.752V240.96z"
                    fill="#FFFFFF"
                  />
                  <path
                    d="M480 828.928v32l-192 66.528v-544l448-199.552v288l-32 11.104v-256l-384 177.344v480z"
                    fill="#5D6D7E"
                  />
                  <path
                    d="M256 810.56v32l-64 22.144v-544l448-199.552v64l-32 11.104v-32L224 341.632v480z"
                    fill="#808FA1"
                  />
                  <path
                    d="M160 747.808v32l-64 22.176v-544l448-199.552v64l-32 11.072v-32L128 278.88v480z"
                    fill="#B7BFC8"
                  />
                  <path
                    d="M544 832h96v96h-96v-96z m0-128h96v96h-96v-96z m0-128h96v96h-96v-96z m128 256h96v96h-96v-96z m0-128h96v96h-96v-96z m128 128h96v96h-96v-96z"
                    fill="#27A2DF"
                  />
                  <path
                    d="M864 544h96v96h-96v-96z m3.872 128l67.904 67.872-67.904 67.904L800 739.84 867.872 672z m-128-128l67.904 67.872-67.904 67.904L672 611.84 739.872 544z"
                    fill="#30AD98"
                  />
                </svg>
              </span>

              <div className="mt-4 md:mx-4 md:mt-0">
                <h1 className="text-2xl font-semibold text-gray-700 capitalize dark:text-white">
                  Custom Cache
                </h1>

                <p className="mt-3 text-gray-500 dark:text-gray-300">
                  Tailored for efficiency, the cache optimizes data retrieval, ensuring
                  a swift and seamless exploration of the Permaweb's globally
                  distributed and permanent archive.
                </p>
              </div>
            </div>
          </div>

          <div className="hidden lg:flex lg:items-center lg:w-1/2 lg:justify-center">
            <div className="pl-16 relative flex items-end flex-col before:block before:absolute before:h-1/6 before:w-4 before:bg-primary-500 before:bottom-0 before:left-0 before:rounded-lg  before:transition-all group-hover:before:bg-orange-300 overflow-hidden">
              <div className="absolute top-0 left-0 bg-blue-500 w-4/6 px-12 py-14 flex flex-col justify-center rounded-xl group-hover:bg-sky-600 transition-all ">
                <span className="block mb-10 font-bold group-hover:text-orange-300">
                  Embarking on distinct realms
                </span>
                <h2 className="text-white font-bold text-3xl">
                  Unleashing Tomorrow's Web with Advanced Arweave Gateways
                </h2>
              </div>
              <a
                className="font-bold text-sm flex mt-2 mb-8 items-center gap-2"
                href="/"
                onClick={(e: any) => {
                  //Add the plan to the session
                  sessionStorage.setItem("plan", "arweave");
                  //Log them into the app
                  // !isAuthenticated
                  //   ? loginWithRedirect()
                  //   : (document.location.href = "/dashboard");
                }}
              >
                <span>GET STARTED</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                  />
                </svg>
              </a>
              <div className="rounded-xl overflow-hidden">
                <img src={gatewayIcon} alt="Custom Arweave Gateway" />
              </div>
            </div>
          </div>
        </div>

        <hr className="border-gray-200 my-12 dark:border-gray-700" />
      </div>
    </section>
  );
}
