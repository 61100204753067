import { useState, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/24/solid"
import StripeUtils from "model/StripeUtils";
import { StripeProduct } from "interfaces/StripeProduct";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useAuth0 } from '@auth0/auth0-react';
export default function Enterprise() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const domain = window.location.origin;
  const [features, setFeatures] = useState<Array<any>>([]);
  const [product, setProduct] = useState<StripeProduct>();
  useEffect(() => {
    const getProduct = async () => {
    //setLoading(true);

      //Setup the Stripe object
      const _StripeUtils = new StripeUtils(domain, undefined);

      const products: Array<StripeProduct> = await _StripeUtils.retrieveVelocity();
      if(typeof products[0] !== "undefined"){
        setFeatures(products[0].features);
      }
      //Set the data for display
      setProduct(products[0]);

      //Stop loading
      //setLoading(false);

      //Show the UI
      //setInit(false);
    };
    //Grab the products and pricing data for the UI
    getProduct();
  }, [domain]);

  return (
    <div className="bg-white dark:bg-neutral-900 py-8 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight dark:gray-900 text-black">
              {product?.name} Plan
            </h3>
            <p className="mt-6 text-base leading-7 text-gray-600 dark:text-white">
              Backpac has the solution for Solana Ore Miners sending RPC
              requests. Our Velocity plan is perfect for Ore mining on Solana
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 dark:gray-900 text-black">
                What’s included
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
              {features.map((feature) => (
                <li key={feature.name} className="flex gap-x-3">
                  <CheckIcon
                    color="#3BA5F3"
                    className="h-6 w-5 flex-none"
                    aria-hidden="true"
                  />
                  {feature.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">
                  Simple pricing
                </p>
                <br />
                {typeof product !== "undefined" && (
                  <h2 className="text-5xl leading-none flex items-center dark:text-neutral-300">
                    <span>
                      ${(product?.price?.unit_amount / 100).toFixed(2)}
                    </span>
                    <span className="text-lg ml-1 font-normal text-neutral-500 dark:text-neutral-300">
                      {product?.price.recurring.interval}
                    </span>
                  </h2>
                )}
                <br />
                <ButtonPrimary
                  onClick={() => {
                    //Add the plan to the session
                    sessionStorage.setItem("plan", product?.metadata["plan"]);
                    //Log them into the app
                    !isAuthenticated
                      ? loginWithRedirect()
                      : (document.location.href = "/dashboard");

                    //document.location.href = "/login?plan=" + pricing.metadata['plan'];
                  }}
                >
                  Get started with {product?.metadata["plan"]}
                </ButtonPrimary>
                {/* <p className="mt-6 text-xs leading-5 text-gray-600">
                  Contact Us with your requirements for Ore Mining.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
