import useArweave from "images/arweave/using-arweave-as-a-database.png";
import arweaveWiki from "images/arweave/arweave-wiki.png";
export default function Ecosystem() {
  return (
    <div id="blog" className="bg-gray-100 dark:bg-neutral-900 px-4 xl:px-4 py-14">
      <div className="mx-auto container">
        <span role="contentinfo">
          <h1 className="focus:outline-none text-center text-3xl lg:text-5xl tracking-wider text-gray-900 dark:text-white">
            Arweave Network
          </h1>
        </span>
        <div
          aria-label="Group of cards"
          className="focus:outline-none mt-12 lg:mt-24"
        >
          <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-8">
            <div className="focus:outline-none" aria-label="card 1">
              <img
                aria-label="Use Arweave as a Database"
                className="focus:outline-none w-full"
                src={useArweave}
                alt="Use Arweave as a Database"
              />
              <div
                className="py-4 px-8 w-full flex justify-between"
                style={{ backgroundColor: "#3BA5F3" }}
              >
                <p className="focus:outline-none text-sm text-white font-semibold tracking-wide">
                  <a
                    href="https://cookbook.arweave.dev/concepts/post-transactions.html"
                    target="_new"
                  >
                    Use Arweave
                  </a>
                </p>
              </div>
              <div className="bg-white dark:bg-neutral-900 px-10 py-6 rounded-bl-3xl rounded-br-3xl">
                <h1 className="focus:outline-none text-4xl text-gray-900 dark:text-white font-semibold tracking-wider">
                  Posting transactions to Arweave
                </h1>
                <p className="focus:outline-none text-gray-700 dark:text-white text-base lg:text-lg lg:leading-8 tracking-wide mt-6 w-11/12">
                  Multiple methods exist for submitting transactions to Arweave,
                  each with unique advantages and limitations. Gateways,
                  positioned between clients and Arweave's peer network, play a
                  crucial role in indexing transactions and optimistically
                  caching data. This optimizes the transaction's queryability in
                  a "Pending" state, ensuring applications built on a gateway
                  remain highly responsive. However, there is a potential risk
                  of transactions dropping out of the optimistic cache if they
                  are not included in a block by the peers.
                </p>
                <div className="w-full flex justify-end">
                  <button className="focus:outline-none focus:ring-2 ring-offset-2 focus:ring-gray-600 hover:opacity-75 mt-4 justify-end flex items-center cursor-pointer">
                    <span className=" text-base tracking-wide text-indigo-700">
                      <a
                        href="https://cookbook.arweave.dev/concepts/post-transactions.html"
                        target="_new"
                      >
                        Read more on Transactions
                      </a>
                    </span>
                    <svg
                      className="ml-3 lg:ml-6 text-indigo-700"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                    >
                      <path
                        d="M11.7998 1L18.9998 8.53662L11.7998 16.0732"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M1 8.53662H19"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="h-5 w-2"></div>
              </div>
            </div>
            <div>
              <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8">
                <div className="focus:outline-none " aria-label="card 2">
                  <img
                    aria-label="Arweave Wiki"
                    className="focus:outline-none w-full"
                    src={arweaveWiki}
                    alt="Arweave Wiki"
                  />
                  <div
                    className="py-2 px-4 w-full flex justify-between"
                    style={{ backgroundColor: "#3BA5F3" }}
                  >
                    <p className="focus:outline-none  text-sm text-white font-semibold tracking-wide">
                      <a href="https://arwiki.wiki/" target="_new">
                        Arweave Wiki
                      </a>
                    </p>
                  </div>
                  <div className="bg-white dark:bg-neutral-900 px-3 lg:px-6 py-4 rounded-bl-3xl rounded-br-3xl">
                    <h1 className="focus:outline-none text-lg dark:text-white text-gray-900 font-semibold tracking-wider">
                      Explore the Arweave wiki
                    </h1>
                    <p className="focus:outline-none text-gray-700 dark:text-white text-sm lg:text-base lg:leading-8 pr-4 tracking-wide mt-2">
                      Arweave is the first protocol that enables permanent data
                      storage.
                    </p>
                    <div className="w-full flex justify-end">
                  <button className="focus:outline-none focus:ring-2 ring-offset-2 focus:ring-gray-600 hover:opacity-75 mt-4 justify-end flex items-center cursor-pointer">
                    <span className=" text-base tracking-wide text-indigo-700">
                      <a
                        href="https://arwiki.wiki/"
                        target="_new"
                      >
                       Read the Wiki
                      </a>
                    </span>
                    <svg
                      className="ml-3 lg:ml-6 text-indigo-700"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                    >
                      <path
                        d="M11.7998 1L18.9998 8.53662L11.7998 16.0732"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M1 8.53662H19"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </button>
                </div>
                  </div>
                </div>
                <div className="focus:outline-none" aria-label="card 3">
                  <img
                    aria-label="gaming"
                    className="focus:outline-none focus:outline-none w-full"
                    src="https://arweave.org/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fcookbook.c69c560e.webp&w=640&q=75"
                    alt="Arweave Cookbook"
                  />
                  <div
                    className="py-2 px-4 w-full flex justify-between"
                    style={{ backgroundColor: "#3BA5F3" }}
                  >
                    <p className="focus:outline-none text-sm text-white font-semibold tracking-wide">
                      <a href="https://cookbook.arweave.dev/" target="_new">
                        Arweave Cookbook
                      </a>
                    </p>
                    {/* <p className="focus:outline-none text-sm text-white font-semibold tracking-wide">
                      13TH Oct, 2020
                    </p> */}
                  </div>
                  <div className="bg-white dark:bg-neutral-900 px-3 lg:px-6 py-4 rounded-bl-3xl rounded-br-3xl">
                    <h1 className="focus:outline-none text-lg dark:text-white text-gray-900 font-semibold tracking-wider">
                      Build on the Permaweb
                    </h1>
                    <p className="focus:outline-none dark:text-white text-gray-700 text-sm lg:text-base lg:leading-8 pr-4 tracking-wide mt-2">
                      A curated collection of developer guides & more to build
                      on the Permaweb with Arweave. All in one place.
                    </p>
                    <div className="w-full flex justify-end">
                  <button className="focus:outline-none focus:ring-2 ring-offset-2 focus:ring-gray-600 hover:opacity-75 mt-4 justify-end flex items-center cursor-pointer">
                    <span className=" text-base tracking-wide text-indigo-700">
                      <a
                        href="https://cookbook.arweave.dev/"
                        target="_new"
                      >
                        More on the Cookbook
                      </a>
                    </span>
                    <svg
                      className="ml-3 lg:ml-6 text-indigo-700"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                    >
                      <path
                        d="M11.7998 1L18.9998 8.53662L11.7998 16.0732"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M1 8.53662H19"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </button>
                </div>
                  </div>
                </div>
              </div>

              <div className="mt-10 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8">
                <div className="focus:outline-none " aria-label="card 4">
                  <img
                    aria-label="laptop"
                    className="focus:outline-none w-full"
                    src="https://arweave.org/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fpermapages.5f3a840a.png&w=640&q=75"
                    alt="PermaPages pages built on Arweave"
                  />
                  <div
                    className="py-2 px-4 w-full flex justify-between"
                    style={{ backgroundColor: "#3BA5F3" }}
                  >
                    <p className="focus:outline-none text-sm text-white font-semibold tracking-wide">
                      <a href="https://permapages.app/" target="_new">
                        PermaPages
                      </a>
                    </p>
                  </div>
                  <div className="bg-white dark:bg-neutral-900 px-3 lg:px-6 py-4 rounded-bl-3xl rounded-br-3xl">
                    <h1 className="focus:outline-none text-lg dark:text-white text-gray-900 font-semibold tracking-wider">
                      Publish to the Permaweb
                    </h1>
                    <p className="focus:outline-none dark:text-white text-gray-700 text-sm lg:text-base lg:leading-8 pr-4 tracking-wide mt-2">
                      Create and manage your own permanent web3 profile and
                      permaweb pages built on Arweave.
                    </p>
                    <div className="w-full flex justify-end">
                  <button className="focus:outline-none focus:ring-2 ring-offset-2 focus:ring-gray-600 hover:opacity-75 mt-4 justify-end flex items-center cursor-pointer">
                    <span className=" text-base tracking-wide text-indigo-700">
                      <a
                        href="https://permapages.app/"
                        target="_new"
                      >
                        More on PermaPages
                      </a>
                    </span>
                    <svg
                      className="ml-3 lg:ml-6 text-indigo-700"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                    >
                      <path
                        d="M11.7998 1L18.9998 8.53662L11.7998 16.0732"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M1 8.53662H19"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </button>
                </div>
                  </div>
                </div>
                <div className="focus:outline-none" aria-label="card 5">
                  <img
                    aria-label="worker"
                    className="focus:outline-none w-full"
                    src="https://arweave.org/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Farwiki.5c1ebe69.png&w=640&q=75"
                    alt="What is Arweave"
                  />
                  <div
                    className="py-2 px-4 w-full flex justify-between"
                    style={{ backgroundColor: "#3BA5F3" }}
                  >
                    <p className="focus:outline-none text-sm text-white font-semibold tracking-wide">
                      <a
                        href="https://arwiki.arweave.dev/#/en/Arweave"
                        target="_new"
                      >
                        What is Arweave?
                      </a>
                    </p>
                  </div>
                  <div className="bg-white dark:bg-neutral-900 px-3 lg:px-6 py-4 rounded-bl-3xl rounded-br-3xl">
                    <h1 className="focus:outline-none dark:text-white text-lg text-gray-900 font-semibold tracking-wider">
                      Understanding Arweave
                    </h1>
                    <p className="focus:outline-none dark:text-white  text-gray-700 text-sm lg:text-base lg:leading-8 pr-4 tracking-wide mt-2">
                      The protocol is secured by an open and decentralized
                      network of miners, who store and replicate data in
                      exchange for AR token rewards.
                    </p>
                    <div className="w-full flex justify-end">
                  <button className="focus:outline-none focus:ring-2 ring-offset-2 focus:ring-gray-600 hover:opacity-75 mt-4 justify-end flex items-center cursor-pointer">
                    <span className=" text-base tracking-wide text-indigo-700">
                      <a
                        href="https://arwiki.arweave.dev/#/en/Arweave"
                        target="_new"
                      >
                        More on Arweave
                      </a>
                    </span>
                    <svg
                      className="ml-3 lg:ml-6 text-indigo-700"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                    >
                      <path
                        d="M11.7998 1L18.9998 8.53662L11.7998 16.0732"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M1 8.53662H19"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </button>
                </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="border-gray-200 my-12 dark:border-gray-700" />
      </div>
    </div>
  );
}
