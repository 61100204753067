//import Flow from "./flow/Flow";
import { useEffect  } from "react";
import { Helmet } from "react-helmet";
import Hero from "./sections/Hero";
import Centered from "./sections/Centered";
import Description from "./sections/Description";
import Enterprise from "./sections/Enterprise";
import QuickNode from "containers/PageHome/sections/Quicknode";
const Features = () => {
  useEffect(() => {

  }, []);


 

  return (
    <>
      <Helmet>
        <title>
          Web3 Provider Redundancy | Multi-RPC Provider Load Balancer
        </title>
        <meta
          name="description"
          content={
            "Backpac provides Web3 Provider Redundancy thru multi-RPC provider blockchain access with a simple pricing model, ensuring you're up and running in minutes. Explore our ultra-fast responsiveness, bulletproof reliability, and seamless scaling for your web3 applications."
          }
        ></meta>
        <meta
          name="keywords"
          content={
            "Web3 Provider Redundancy, Smart RPC Load Balancer, Blockchain RPC Connectivity, Multi-RPC Provider, Blockchain Access, Reliable RPC Service, Web3 Companies, Ultra-Fast Responsiveness, Bulletproof Reliability, Seamless Scaling, RPC Load Balancer, Top RPC Providers, Custom RPC Providers, Ethereum, Coinbase, Polygon, Solana, Binance Smart Chain, Enterprise Plans"
          }
        ></meta>
      </Helmet>
      <Hero />
      {/* <CTA /> */}
        <div className="mx-auto grid  grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-6 sm:px-32 sm:py-6 lg:max-w-7xl lg:grid-cols-1 lg:px-8">
          <Description />
        </div>
      <Enterprise />
      <QuickNode />
      <Centered />
      
    </>
  );
};
export default Features;
