
const MultiProvider = () => {
  return (
    <section className="relative py-28 bg-gray-900">
      <div className="p-2 relative max-w-2xl mx-auto sm:text-center">
        <div className="relative z-10">
          <h3 className="font-semibold text-neutral-900 text-3xl xl:text-4xl" style={{ color: "#3BA5F3" }}>
            Web3 Provider Redundancy
          </h3>
          <p className="mt-3 text-white dark:text-neutral-300">
            Boost your application's rate limit with ease by seamlessly
            distributing traffic across multiple RPC node providers
            through a single RPC Endpoint, ensuring RPC request is fulfilled.
          </p>
        </div>
      </div>

      <div className="flex flex-wrap justify-center p-12">
        {/* Without Backpac */}
        <div className="p-5 rounded-lg border border-gray-300 mb-4 mr-2 flex-shrink-0 w-full sm:w-auto">
          <h4 className="text-2xl font-bold mb-4 text-white">
            Without Backpac
          </h4>
          <div className="line-numbers-wr bg-black text-white rounded-lg">
            <pre className="line-numbers language-javascript">
              <code className="language-javascript text-sm whitespace-pre-wrap">
                <ul className="list-inside" style={{ marginLeft: "3px" }}>
                  <li key={'Without-' + 1}>
                    <span className="text-blue-500">const</span> Web3 ={" "}
                    <span className="text-purple-500">require</span>(
                    <span className="text-yellow-500">'web3'</span>);
                  </li>
                  <li key={'Without-' + 2}>
                    <span> </span>
                  </li>
                  <li key={'Without-' + 3}>
                    <span className="text-gray-500">
                      &#47;&#47;Send request to RPC node provider
                    </span>
                  </li>
                  <li key={'Without-' + 4}>
                    <span className="text-blue-500">const</span> web3 ={" "}
                    <span className="text-blue-500">new</span> Web3(
                    <span className="text-yellow-500">
                      'rpc.public.com'
                    </span>
                    );
                  </li>
                  <li key={'Without-' + 5}>
                    <span className="text-gray-500">
                      &#47;&#47;EXECUTE: RPC request (Public RPC Endpoint)
                    </span>
                  </li>
                  <li key={'Without-' + 6}>“method” : "eth_getBlockByNumber"</li>
                  <li key={'Without-' + 7}>
                    <span> </span>
                  </li>
                  <li key={'Without-' + 8}>
                    <span className="text-red-500 text-semibold">
                    &#47;HTTP 429: Too many requests. Rate limit exceeded.
                    </span>
                  </li>
                  <li key={'Without-' + 9}>
                    <span>&nbsp;</span>
                  </li>
                  <li key={'Without-' + 10}>
                  <span>&nbsp;</span>
                  </li>
                  <li key={'Without-' + 11}>
                  <span>&nbsp;</span>
                  </li>
                  <li key={'Without-' + 12}>
                  <span>&nbsp;</span>
                  </li>
                  <li key={'Without-' + 13}>
                  <span>&nbsp;</span>
                  </li>
                </ul>
              </code>
            </pre>
          </div>
        </div>

        {/* Using Backpac */}
        <div className="p-5 rounded-lg border border-green-500 mb-4 mr-2 flex-shrink-0 w-full sm:w-auto">
          <h4 className="text-2xl font-bold mb-4 text-white">With Backpac</h4>
          <div className="line-numbers-wr bg-black text-white rounded-lg">
            <pre className="line-numbers language-javascript">
              <code className="language-javascript text-sm whitespace-pre-wrap">
                <ul className="list-inside" style={{ marginLeft: "3px" }}>
                  <li key={'With-' + 1}>
                    <span className="text-blue-500">const</span> Web3 ={" "}
                    <span className="text-purple-500">require</span>(
                    <span className="text-yellow-500">'web3'</span>);
                  </li>
                  <li key={'With-' + 2}>
                    <span> </span>
                  </li>
                  <li key={'With-' + 3}>
                    <span className="text-gray-500">
                      &#47;&#47;Send request to RPC node provider
                    </span>
                  </li>
                  <li key={'With-' + 4}>
                    <span className="text-blue-500">const</span> web3 ={" "}
                    <span className="text-blue-500">new</span> Web3(
                    <span className="text-yellow-500">
                      'rpc.backpac.xyz/{"{key}"}'
                    </span>
                    );
                  </li>
                  <li key={'With-' + 5}>
                    <span className="text-gray-500">
                      &#47;&#47;EXECUTE: RPC request (Public RPC Endpoint)
                    </span>
                  </li>
                  <li key={'With-' + 6}>“method” : "eth_getBlockByNumber"</li>
                  <li key={'With-' + 7}>
                    <span> </span>
                  </li>
                  <li key={'With-' + 8}>
                    <span className="text-red-500 text-semibold">
                    &#47;HTTP 429: Too many requests. Rate limit exceeded.
                    </span>
                  </li>
                  <li key={'With-' + 9}>
                    <span className="text-orange-500 text-semibold">
                      FAILOVER TO:{" "}
                      <span className="text-blue-500">
                        QuickNode RPC Endpoint
                      </span>
                    </span>
                  </li>
                  <li key={'With-' + 10}>
                    <span className="text-gray-500">
                      &#47;&#47;EXECUTE: RPC request (QuickNode RPC Endpoint)
                    </span>
                  </li>
                  <li key={'With-' + 11}>“method” : "eth_getBlockByNumber"</li>
                  <li key={'With-' + 12}>
                    <span> </span>
                  </li>
                  <li key={'With-' + 13}>
                    <span className="text-green-600 text-semibold">
                    &#47;HTTP 200
                    </span>
                  </li>
                </ul>
              </code>
            </pre>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 max-w-md mx-auto h-72 blur-[118px]" style={{ background: "linear-gradient(152.92deg, rgba(59, 165, 243, 1) 4.54%, rgba(59, 165, 243, 0.5) 34.2%, rgba(59, 165, 243, 0.2) 77.55%)" }}></div>
    </section>
  );
};

export default MultiProvider;
