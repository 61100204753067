import { FC, useState, useEffect  } from "react";
import { useSearchParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useAuth0, } from '@auth0/auth0-react';
import { invite, User, invitation } from "interfaces/Auth0";
import Auth0 from "model/Auth0";
import googleSvg from "images/Google.svg";
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
export interface PageLoginProps {
  className?: string;
}




const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {

  const { loginWithRedirect, isAuthenticated } = useAuth0();
  //const navigate = useNavigate();
  const [search] = useSearchParams();
  const [email, setEmail] = useState<string>('');
  const [pass, setPass] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error] = useState('');
  const [isInvite, setIsInvite] = useState(false);
  const [invite, setInvite] = useState<invite|undefined>(undefined);
  const [invitation, setInvitation] = useState<invitation|undefined>(undefined);


  const toastConfig: ToastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  }


   useEffect(() => {
     if (search.get("invitation") !== null) {
       setLoading(true);
       setIsInvite(true);
      
       const invite: invite = {
         invitation: search.get("invitation") || "",
         organization: search.get("organization") || "",
         organization_name: search.get("organization_name") || "",
       };
       setInvite(invite);
       const toastId = Date.now();
       const _auth0 = new Auth0();

       toast.promise(
        _auth0
         .getInvitationToOrganization(invite.organization, invite.invitation),
        {
          pending: {
            render(){
              return `Validating Invitation to ${invite.organization_name}`
            },
            icon: false,
            isLoading: true,
            toastId
          },
          success: {
            render({data}){
              if (typeof data !== "undefined") {
                setInvitation(data);
                setEmail(data.invitee.email);
                setLoading(false);
                return "Invitation Validated. Please enter a password for your account.";
              }else{
                setLoading(false);
                return "Invitation not valid";
              }
            },
            // other options
            icon: "🟢",
            position: "top-right",
            autoClose: 7500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            isLoading: false,
            theme: "colored",
            toastId
          },
          error: {
            render({data}){
              // When the promise reject, data will contains the error
              setLoading(false);
              console.error(data);
              return "Invitation Invalid";
            },
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            isLoading: false,
            toastId
          }
        }
    )

      //  _auth0
      //    .getInvitationToOrganization(invite.organization, invite.invitation)
      //    .then((value) => {
      //      if (typeof value !== "undefined") {
      //        setEmail(value.invitee.email);
      //        setLoading(false);
      //      }
      //      return value;
      //    })
      //    .catch((error) => {
      //      setLoading(false);
      //    });
     }

     if (isAuthenticated) {
       console.log(isAuthenticated);
       //navigate("/dashboard");
     }
   }, [isAuthenticated, search]);

  // const handleSubmit = async (event: any) => {
  //   event.preventDefault();
  //   setLoading(true);
  //   if (!email) {
  //     setLoading(false);
  //     setError("Email is Invalid");
  //     return;
  //   }
  //   try {

  //   } catch (error) {
  //     setError("Unable to log in");
  //     setLoading(false);
  //     console.error(error);
  //   }
  // };

  const loginSocials = [
    {
      name: "Continue with Google",
      href: "#",
      icon: googleSvg,
      onclick: async (event: any) => {
        try {
          console.log(window.location.origin);
          loginWithRedirect({
            authorizationParams: {
              //organization: "org_Cw2TgvpP28e0I7f2",
            },
          });
        } catch (error) {
          console.error(error);
        }
      }
    },
  ];




  if(isInvite === true){
 
    return (
      <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
        <Helmet>
          <title>Invitation for {invite?.organization_name}</title>
        </Helmet>
        <div className="container mb-24 lg:mb-32">
          <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Invitation for {invite?.organization_name}
          </h2>
          <div className="max-w-md mx-auto space-y-6 ">
            {/* FORM */}
            <form className="grid grid-cols-1 gap-6" action="#" method="post">
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Email address
                </span>
                <Input
                  type="email"
                  name="inviteEmail"
                  id="inviteEmail"
                  placeholder="john@smith.com"
                  value={email}
                  disabled={true}
                  className="mt-1"
                />
              </label>
              <label className="block">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                  Password
                </span>
                <Input type="password" className="mt-1" disabled={loading} value={pass} onChange={ e =>{
                  setPass(e.target.value)
                }} />
              </label>
              <ButtonPrimary disabled={loading}
              type="submit"
              onClick={() =>{
                //e.preventDefault();
                setLoading(true);
                const id = toast.loading("Accepting Invitation", toastConfig);
                
                if(email !== "" && pass !== ""){

                 toast.update(id, { render: "Checking Password", type: "info", isLoading: true });

      

                  var passwordValidator = require("password-validator");
                  let schema = new passwordValidator();
                  schema
                    .is()
                    .min(6) // Minimum length 8
                    .is()
                    .max(16) // Maximum length 16
                    .has()
                    .uppercase() // Must have uppercase letters
                    .has()
                    .lowercase() // Must have lowercase letters
                    .has()
                    .digits() // Must have digits
                    .has()
                    .not()
                    .spaces() // Should not have spaces
                    .is()
                    .not()
                    .oneOf(["Passw0rd", "Password123"]);
              
                    if(schema.validate(pass) === false){
                      toast.update(id, {
                        render: "Password must be at least 8 characters (Max 16). Must have uppercase letters. Must have lowercase letters. Must have digits",
                        type: "error",
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        isLoading: false,
                        theme: "colored",
                      });
                      setLoading(false);
                      return false;
                    }


                  //Create the User
                  toast.update(id, { render: "Processing", type: "info", isLoading: true });
                  console.log('Password Good');
                  const _auth0 = new Auth0();
                  const user: User = {
                    email: email,
                    password: pass,
                    blocked: false,
                    connection: invitation?.connection_id,
                    created_at: undefined,
                    phone_number: undefined,
                    verify_email: undefined,
                    email_verified: undefined,
                    phone_verified: undefined,
                    identities: undefined,
                    username: undefined,
                    name: undefined,
                    nickname: undefined,
                    given_name: undefined,
                    family_name: undefined,
                    picture: undefined,
                    updated_at: undefined,
                    user_id: undefined,
                    user_metadata: undefined,
                    app_metadata: undefined,
                    last_ip: undefined,
                    last_login: undefined,
                    logins_count: undefined,
                  }

                  _auth0
                    .createUser(user)
                    .then((value) => {
                      if (typeof value !== "undefined") {
                        
                        setLoading(false);
                      }
                      return value;
                    })
                    .catch((error) => {
                      toast.update(id, {
                        render: error.message,
                        type: "error",
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        isLoading: false,
                        theme: "colored",
                      });
                      setLoading(false);
                    }); 



                  // loginWithRedirect({
                  //   authorizationParams: {login_hint: email, organization: invite?.organization}
                  // })
                }
              }}>Accept Invite</ButtonPrimary>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || Backpac Inc</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login or Sign Up
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                key={index}
                href="/"
                onClick={item.onclick}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>

          {/* FORM */}
          <form className="grid grid-cols-1 gap-6">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="john@smith.com"
                className="mt-1"
                onChange={(e) => {
                  setEmail(e.target.value);
                  //Check the email and pull the orgId
                  //setOrgId("1234");
                }}
                disabled={loading}
              />
            </label>
            <ButtonPrimary
              disabled={loading}
              type="submit"
              onClick={() => {
                if (email !== "") {
                  loginWithRedirect({
                    authorizationParams: {
                      login_hint: email,
                      //organization: orgId,
                    },
                  });
                }
              }}
            >
              Login
            </ButtonPrimary>
          </form>
          {typeof error !== "undefined" ? (
            <Alert key={"login"} variant={"danger"}>
              {error}
            </Alert>
          ) : null}
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            <Link to="/signup" className="text-green-600">
              Sign Up
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
