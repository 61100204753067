import HeaderLogged from "components/Header/HeaderLogged";
import Header2 from "components/Header/Header2";
import HeaderEmpty from 'components/Header/HeaderEmpty';
import { useAuth0 } from '@auth0/auth0-react';

const SiteHeader = () => {
  const { isAuthenticated } = useAuth0();


  if(document.location.pathname === "/health"){
    return <></>
  }

  if(document.location.pathname === "/signup" || document.location.pathname === "/dashboard"){
    return <HeaderEmpty />
  }

  if(isAuthenticated){
    return <HeaderLogged />
  }

  return  <Header2 />;
};
export default SiteHeader;