//import Flow from "./flow/Flow";
import { useEffect  } from "react";
import { Helmet } from "react-helmet";
import Hero from "./sections/Hero";
import QuickNode from "containers/PageHome/sections/Quicknode";
import Velocity from "./sections/Velocity";
import Benefits from "./sections/Benefits";
import RPCProviders from "shared/RPCProviders/RPCProviders";
import SolanaRPC from "./sections/SolanaRPC";
const ORE = () => {
  useEffect(() => {


  }, []);


  return (
    <>
      <Helmet>
        <title>
         Solana ORE Mining
        </title>
        <meta
          name="description"
          content={
            "Mine Solana ORE with an RPC Load Balancer"
          }
        ></meta>
        <meta
          name="keywords"
          content={
            "Solana RPC providers, ORE mining, solana ore, rate limits, Backpac, cryptocurrency mining, efficiency, profitability, blockchain data access, RPC requests, mining challenges, optimizing operations"
          }
        ></meta>
                                <meta
          name="twitter:image"
          content="https://image.nft.backpac.xyz/crop.png"
        />
        <meta
          name="twitter:image:alt"
          content="Mine Solana ORE"
        />
        <meta
          property="og:image"
          content="https://image.nft.backpac.xyz/ICON_WHITE_1024_1024.jpg"
        />
        <meta
          property="og:image:alt"
          content="Solana Ore Mining"
        />
      </Helmet>
      <Hero />
      <Benefits/>
      <Velocity />
      <RPCProviders blockchain={"solana"} />
      <QuickNode />
      <SolanaRPC />
      {/* <Providers /> */}
      
      
      
    </>
  );
};
export default ORE;
