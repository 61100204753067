import { ArrowPathIcon, CloudArrowDownIcon, DocumentMagnifyingGlassIcon,  AdjustmentsVerticalIcon} from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Tailored Gateway Solutions',
    description:
      'Crafted enterprise-grade gateways to meet diverse app needs, designed specifically for applications with elevated gateway service needs.',
    icon: AdjustmentsVerticalIcon,
  },
  {
    name: 'Optimized CDN Evolution',
    description:
      'Redefined global web app excellence unleashed via advanced CDN services beyond conventional standards.',
    icon: CloudArrowDownIcon,
  },
  {
    name: 'Innovative Data Search',
    description:
      'Arweave-based data search will go beyond transaction tags, the evolution of content-based searches and navigate the dynamics of Web3 data ownership.',
    icon: DocumentMagnifyingGlassIcon,
  },
  {
    name: 'Streaming Excellence',
    description:
      'Steering through high-speed, transient streaming service demands, we pioneer solutions for time-sensitive data without the need for permanent storage.',
    icon: ArrowPathIcon,
  },
]

export default function Next() {
  return (
    <div className="bg-white dark:bg-neutral-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600 ">
            Future of Arweave Gateways
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
            Unleashing Tomorrow's Web with Advanced Arweave Gateways
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900 dark:text-indigo-600">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <feature.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600 dark:text-white">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div className="mx-auto max-w-2xl lg:text py-6">
        <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">
            In shaping the future of Arweave, Backpac envisions a revamped CDN
            service, transcending traditional models. Our gateway strives to
            harmonize dynamic and static content, a crucial need for expansive
            web applications. Beyond the commonplace, we explore high-speed
            streaming and customizable gateways.
          </p>
          <p className="mt-6 text-lg text-gray-600 dark:text-white">
            In shaping the future of Arweave, Backpac envisions a revamped CDN
            service, transcending traditional models. Our gateway strives to
            harmonize dynamic and static content, a crucial need for expansive
            web applications. Beyond the commonplace, we explore high-speed
            streaming and customizable gateways.
          </p>
        </div>
        <hr className="border-gray-200 my-12 dark:border-gray-700" />
      </div>
      
    </div>
  );
}
