import axios from 'axios';


export default class Slack {
  readonly hook: string | undefined;

  constructor(url: string | undefined) {
    this.hook = typeof url !== "undefined" ? url : process.env.REACT_APP_SLACK_CONTACTUS_WEBHOOK;
  }

  updateWaitlist = async (email: string) => {
    let res = await axios.post(this.hook || "", JSON.stringify({
      "text": `New Direct SignUp: ${email}`,
  }), {
      withCredentials: false,
      transformRequest: [(data, headers) => {
          if (typeof headers.post !== "undefined"){
            //delete headers.post["ContentType"]
          }
          return data
      }]
  })

  if (res.status === 200) {
      return {success: true, res}
  } else {
      return {success: false, res}
  }
  };
  contactUs = async (email: string, name: string, subject: string, message: string) => {
    let res = await axios.post(this.hook || "", JSON.stringify({
      "text": `Email Address: ${email}\n Name: ${name} \nSubject: ${subject}\n Message: ${message}`,
  }), {
      withCredentials: false,
      transformRequest: [(data, headers) => {
        if (typeof headers.post !== "undefined"){
          //delete headers.post["Content-Type"]
        }
          return data
      }]
  })

  if (res.status === 200) {
      return {success: true, res}
  } else {
      return {success: false, res}
  }
  };
  waitlist = async (email: string, name: string, subject: string, message: string) => {
    let res = await axios.post(this.hook || "", JSON.stringify({
      "text": `Email Address: ${email}\n Name: ${name} \nSubject: ${subject}\n Message: ${message}`,
  }), {
      withCredentials: false,
      transformRequest: [(data, headers) => {
        if (typeof headers.post !== "undefined"){
          //delete headers.post["Content-Type"]
        }
          return data
      }]
  })

  if (res.status === 200) {
      return {success: true, res}
  } else {
      return {success: false, res}
  }
  };
  updateRating = (path: string, req: any) => {
    return axios.post(path, req);
  };
}