import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import BlogPostCardMeta from "components/PostCardMeta/BlogPostCardMeta";
export interface CardLatestPostProps {
  className?: string;
  post: any
}

const CardLatestPost: FC<CardLatestPostProps> = ({ className = "h-full", post }) => {
  const slug = (typeof post.slug !== "undefined" ? post.slug.toLowerCase() : "")
  return (
    <div
      className={`nc-Card3 relative flex flex-col-reverse sm:flex-row sm:items-center rounded-[40px] group ${className}`}
      data-nc-id="CardLatestPost"
    >
      <div className="flex flex-col flex-grow">
        <div className="space-y-5 mb-4">
          <Badge name={post.tag} />
          <div>
            <h2
              className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 text-xl`}
            >
              <Link
                to={`/blog/${slug}`}
                className="line-clamp-2 capitalize"
                title={"title"}
              >
                {post.title}
              </Link>
            </h2>
            <div className="hidden sm:block sm:mt-2">
              <span className="text-neutral-500 dark:text-neutral-400 text-base line-clamp-1">
                <div
                  dangerouslySetInnerHTML={{
                    __html: post.description,
                  }}
                  className="mt-10"
                ></div>
              </span>
            </div>
          </div>
          <BlogPostCardMeta author={post.author} date={post.date} avatar={(typeof post.avatar !== "undefined" ? post.avatar[0].url : post.avatar)} />
        </div>
      </div>

      <div
        className={`block flex-shrink-0 sm:w-56 sm:ml-6 rounded-3xl overflow-hidden mb-5 sm:mb-0`}
      >
        <Link
          to={`/blog/${slug}`}
          className={`block w-full h-0 aspect-h-9 sm:aspect-h-16 aspect-w-16 `}
        >
          <NcImage
            src={
              typeof post.featured !== "undefined"
                ? post.featured[0].url
                : post.featured
            }
            containerClassName="absolute inset-0"
          />
        </Link>
      </div>
    </div>
  );
};

export default CardLatestPost;
