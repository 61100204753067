import { FC } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Navigation from "shared/Navigation/Navigation";
import { useAuth0 } from '@auth0/auth0-react';

export interface MainNav2Props {}

const MainNav2: FC<MainNav2Props> = () => {
  const { isLoading, loginWithRedirect } = useAuth0();
  return (
    <div className={`nc-MainNav2 relative z-10 ${"onTop "}`}>
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo />
        </div>
          <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
            <div className="hidden items-center xl:flex space-x-2">
              <Navigation />
              <div className="hidden sm:block h-10 border-l border-neutral-300 dark:border-neutral-6000"></div>
              <SwitchDarkMode />
              {isLoading === false && (
                <ButtonPrimary onClick={ () =>{
                  loginWithRedirect()
                }} sizeClass="px-4 py-2 sm:px-5">
                  Dashboard
                </ButtonPrimary>
              )}
            </div>
            <div className="flex items-center space-x-1.5 xl:hidden">
              {isLoading === false && (
                <ButtonPrimary onClick={ () =>{
                  loginWithRedirect()
                }} sizeClass="px-4 py-2 sm:px-5">
                  Dashboard
                </ButtonPrimary>
              )}
              <MenuBar />
            </div>
          </div>
      </div>
    </div>
  );
};
export default MainNav2;