import Endpoint from "./Endpoint";
import Company from "./Company";
import iCompany from "../interfaces/Company";
//import { User as Auth0User } from "interfaces/Auth0";

export default class User {
  didToken: string | null;
  issuer: string | null | undefined;
  email: string | null;
  name: string | null;
  username: string | null;
  twitter: string | null;
  companyDID: string | null;
  status: boolean;
  timeStamp: string | null;
  isoDate: string | null;
  week: number | null;
  created: string | null;
  updatedAt: string | null;
  company: Company | null;

  constructor(
    didToken: string | null,
    issuer: string | null | undefined,
    email: string | null,
    name: string | null,
    username: string | null,
    twitter: string | null,
    companyDID: string | null
  ) {
    this.didToken = didToken || null;
    this.issuer = issuer || null;
    this.email = email || null;
    this.username = username || null;
    this.name = name || null;
    this.companyDID = companyDID || null;
    this.twitter = twitter || null;

    this.timeStamp = null;
    this.isoDate = null;
    this.week = null;
    this.created = null;
    this.updatedAt = null;

    this.company = null;
    this.status = true;
  }
  get = async () => {
    const _Endpoint = new Endpoint();
    try {
      let user = await _Endpoint.post("user/view", { issuer: this.issuer });
      console.log(user);
      user = user.data.user;

      this.email = user.email || null;
      this.username = user.username || null;
      this.name = user.name || null;
      this.companyDID = user.companyDID || null;
      this.twitter = user.twitter || null;
  
      this.timeStamp = user.timeStamp;
      this.isoDate = user.isoDate;
      this.week = user.week;
      this.created = user.created;
      this.updatedAt = user.updatedAt;
      this.status = user.status === "VALID" ? true : false;

    } catch (e: any) {
      console.log("get Error", e.message);
    }
  };
  addCompany = async (company: iCompany) => {

    console.log(company);
    const _Endpoint = new Endpoint();
    console.log("Endpoint", company)
    let companyAdd = await _Endpoint.postApiClientWithAdditionalParams(
      "/company",
      company,
      {
        headers: { did_token: this.didToken, platform: "desktop" },
      }
    );
    companyAdd = companyAdd.data.data;

    this.companyDID = companyAdd.state + ":" + companyAdd.companyId;

    console.log(companyAdd);
  };
  getCompany = async () => {
    const _Endpoint = new Endpoint();
    let company = await _Endpoint.getApiClientWithAdditionalParams(
      "/company/user",
      {
        headers: { did_token: this.didToken, platform: "desktop" },
      }
    );
    company = company.data.data;
    this.company = new Company(
      company.state,
      company.companyId,
      company.name,
      company.email,
      company.description,
      company.website,
      company.twitter,
      company.facebook,
      company.imgUrl,
      company.developer
    );
  };
  updateCompany = async () => {
    const _Endpoint = new Endpoint();
    let companyUpdated = await _Endpoint.postApiClientWithAdditionalParams(
      "/user/company",
      {companyDID: this.companyDID},
      {
        headers: { did_token: this.didToken, platform: "desktop" },
      }
    );
    console.log(companyUpdated)
  };
  create = () => {};
  updateCompanyDID = async () => {
    const _Endpoint = new Endpoint();
    let companyUpdated = await _Endpoint.postApiClientWithAdditionalParams(
      "/user/company",
      {companyDID: this.companyDID},
      {
        headers: { did_token: this.didToken, platform: "desktop" },
      }
    );
    console.log(companyUpdated)
  };
  update = () => {};
  delete = () => {};
  exists = () => {};
}