import React from 'react';

const EthereumResources = () => {
  return (
    <div className="container mx-auto mt-8">
      <h2 className="text-3xl font-bold mb-4">
        Multi blockchain network connectivity
      </h2>
      <p className="text-gray-700 mb-8">
        Backpac empowers your blockchain operations by seamlessly supporting
        multiple networks within the Ethereum blockchain ecosystem.
      </p>

      <div className="flex grid space-x-4">
        {/* Explorer Section */}
        <div className="flex-1 flex-col border rounded-md overflow-hidden">
          <div className="bg-gray-200 p-4 border-b">
            <div className="flex items-center">
              <div className="bg-red-500 w-3 h-3 rounded-full mr-2"></div>
              <div className="bg-yellow-500 w-3 h-3 rounded-full mr-2"></div>
              <div className="bg-green-500 w-3 h-3 rounded-full"></div>
            </div>
          </div>
          <div className="p-6">
            <h3 className="text-xl font-bold mb-2">Mainnet</h3>
            <p className="text-gray-700 mb-4">
              The primary and original blockchain network of the Ethereum
              ecosystem, serving as the foundation for decentralized
              applications (DApps) and smart contracts.
            </p>
            <a
              href="https://ethereum.org/en/"
              target="_new"
              className="text-blue-500 hover:underline"
            >
              LEARN MORE
            </a>
          </div>
        </div>

        {/* Testnet Section */}
        <div className="flex-1 flex-col border rounded-md overflow-hidden">
          <div className="bg-gray-200 p-4 border-b">
            <div className="flex items-center">
              <div className="bg-red-500 w-3 h-3 rounded-full mr-2"></div>
              <div className="bg-yellow-500 w-3 h-3 rounded-full mr-2"></div>
              <div className="bg-green-500 w-3 h-3 rounded-full"></div>
            </div>
          </div>
          <div className="p-6">
            <h3 className="text-xl font-bold mb-2">Goerli</h3>
            <p className="text-gray-700 mb-4">
              A cross-client testnet for Ethereum, designed to facilitate
              testing and development activities in a interoperable environment.
            </p>
            <a
              href="https://goerli.net/"
              target="_new"
              className="text-blue-500 hover:underline"
            >
              LEARN MORE
            </a>
          </div>
        </div>

        {/* Faucet Section */}
        <div className="flex-1 flex-col border rounded-md overflow-hidden">
          <div className="bg-gray-200 p-4 border-b">
            <div className="flex items-center">
              <div className="bg-red-500 w-3 h-3 rounded-full mr-2"></div>
              <div className="bg-yellow-500 w-3 h-3 rounded-full mr-2"></div>
              <div className="bg-green-500 w-3 h-3 rounded-full"></div>
            </div>
          </div>
          <div className="p-6">
            <h3 className="text-xl font-bold mb-2">Sepolia</h3>
            <p className="text-gray-700 mb-4">
              Sepolia stands out as a fast and efficient testnet designed for
              streamlined application development. It boasts a closed validator
              set, controlled by client and testing teams, ensuring a controlled
              environment.
            </p>
            <a
              href="https://sepolia.dev/"
              target="_new"
              className="text-blue-500 hover:underline"
            >
              LEARN MORE
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EthereumResources;
