//import Flow from "./flow/Flow";
import { useEffect  } from "react";
//import { PopupButton } from "@typeform/embed-react";
import { Helmet } from "react-helmet";
import Plus from "./sections/Plus";
import Networks from "./sections/Networks";
import Features from "./sections/Features";
import Connect from "./sections/Connect";
import Enterprise from "./sections/Enterprise";
import QuickNode from "./sections/Quicknode";
import DAO from "images/dao-2.png";
import SIGNUPICON from "images/icons/sign-up.png";
import DASHBOARDICON from "images/icons/dashboard.png"
import Providers from "./sections/Providers";
import FeaturesAcrossProviders from "./sections/FeaturesAcrossProviders";
import MultiProvider from "./sections/MultiProvider";
import { useAuth0 } from '@auth0/auth0-react';
import ButtonSecondaryTrans from "shared/Button/ButtonSecondaryTrans";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BookADemo from "containers/PageSubscription/BookADemo";
const Home = () => {
  const {isAuthenticated, loginWithRedirect } = useAuth0();
  useEffect(() => {
    const plan = sessionStorage.getItem('plan');
    if(plan !== null){
      console.log(plan)
    }

  }, []);
  
  // Example usage:
  //const myTextList = ["Text 1", "Text 2", "Text 3", "Text 4"];
  //const randomText = getRandomText(myTextList);
  //console.log(randomText);
  // function getRandomElementFromList(elementList: Array<string>) {
  //   if (Array.isArray(elementList) && elementList.length > 0) {
  //     const randomIndex = Math.floor(Math.random() * elementList.length);
  //     return elementList[randomIndex];
  //   } else {
  //     return '<h1 className="title-font tracking-wide text-6xl md:text-6xl xl:text-6xl mb-4 font-bold text-neutral-900 dark:text-neutral-100 "><span style={{ color: "#3BA5F3" }}>Fast</span> and <span style={{ color: "#3BA5F3" }}>Resilient</span> Blockchain Access</h1>';
  //   }
  // }
  
  // // Example usage:
  // const elements = [
  //   '<h1 className="title-font tracking-wide text-6xl md:text-6xl xl:text-6xl mb-4 font-bold text-neutral-900 dark:text-neutral-100 "><span style={{ color: "#3BA5F3" }}>Fast</span> and <span style={{ color: "#3BA5F3" }}>Resilient</span> Blockchain Access</h1>',
  //   '<h1 className="title-font tracking-wide text-6xl md:text-6xl xl:text-6xl mb-4 font-bold text-neutral-900 dark:text-neutral-100 "><span style={{ color: "#3BA5F3" }}>One</span> and <span style={{ color: "#3BA5F3" }}>RPC Endpoint</span> Multiple Node Providers</h1>',
  //   // Add more elements as needed
  // ];

  //const randomElement = getRandomElementFromList(elements);
 //console.log(randomElement);

  return (
    <>
      <Helmet>
        <title>An RPC load balancer for scaling blockchain data access</title>
        <meta
          name="description"
          content={
            "Backpac is a load balancer for scaling blockchain data access across blockchains networks."
          }
        ></meta>
        <meta
          name="keywords"
          content={
            "High Throughput RPC Blockchain Access, throughput, across Blockchain Networks, Web3 Provider Redundancy, Smart RPC Load Balancer, Blockchain RPC Connectivity, Multi-RPC Provider, Blockchain Access, Reliable RPC Service, Web3 Companies, Ultra-Fast Responsiveness, Bulletproof Reliability, Seamless Scaling, RPC Load Balancer, Top RPC Providers, Custom RPC Providers, Ethereum, Coinbase, Polygon, Solana, Binance Smart Chain, Enterprise Plans"
          }
        ></meta>
        <meta
          name="twitter:image"
          content="https://image.nft.backpac.xyz/crop.png"
        />
        <meta
          name="twitter:image:alt"
          content="A RPC load balancer for scaling blockchain data access"
        />
        <meta
          property="og:image"
          content="https://image.nft.backpac.xyz/ICON_WHITE_1024_1024.jpg"
        />
        <meta
          property="og:image:alt"
          content="A RPC load balancer for scaling blockchain data access"
        />
      </Helmet>
      {/* <Flow />  */}
      <section className="text-black body-font" style={{marginBottom: '10px'}}>
      <div className="p-3 relative max-w-2xl mx-auto sm:text-center sm:space-x-5 mt-6 sm:mt-12">
        <div className="relative z-10">
        <h1 className="title-font tracking-wide text-4xl xl:text-6xl mb-4 font-bold text-neutral-900 dark:text-neutral-100 ">
            <span style={{ color: "#3BA5F3" }}>Load Balance</span><br />RPC Request
            {/* Blockchain RPC Request */}
        </h1>
          <p className="mt-3 text-neutral-700 dark:text-neutral-300">
          <b>
                <span style={{ color: "#1D476D" }}>Back</span>
                <span style={{ color: "#3BA5F3" }}>pac&trade;</span>
                <i>{' Gateway '}</i>
              </b> 
              is an intelligent RPC load balancer for scaling blockchain data
              access to blockchain networks. Distribute RPC request across
              multiple node providers of your choice, providing you with
              redundancy, increased rate limits and caching.
              {/* reliability, scalability and cost savings. */}
          </p>
        </div>
        <div className="relative z-10 sm:space-x-5 mt-6 sm:mt-12">
              <ButtonPrimary
                onClick={() => {
                  //const url = "https://docs.backpac.xyz/";
                  //window.open(url, "_blank");
                  !isAuthenticated
                    ? loginWithRedirect()
                    : (document.location.href = "/dashboard");
                }}
                style={{
                  backgroundColor: "#3BA5F3",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className=""
              >
                {/* <span role="img" aria-label="quicknode">
                  <img
                    style={{ marginRight: "5px" }}
                    src={!isAuthenticated ? SIGNUPICON : DASHBOARDICON}
                    alt={
                      !isAuthenticated
                        ? "Backpac Sign Up"
                        : "Go To Backpac Dashboard"
                    }
                    className="object-cover object-center rounded w-6 h-6"
                  />
                </span> */}
                {isAuthenticated ? "Dashboard" : "Try Load Balancer"}
              </ButtonPrimary>
              <ButtonSecondaryTrans
                onClick={() => {
                  window.open(
                    "https://meetings.hubspot.com/allyn-alford",
                    "_blank"
                  );
                }}
                className=""
              >
                Book a Demo <span aria-hidden="true">→</span>
              </ButtonSecondaryTrans>
            </div>
        <div
          className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]"
          style={{
            background:
              "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)",
          }}
        ></div>
      </div>
      </section>
      
      <QuickNode />
      <Features />
      <MultiProvider />
      <Providers />
      <FeaturesAcrossProviders />
      
      <Networks />
      

      <Plus />
      
      
      
      <BookADemo />
      
      {/* <Enterprise /> */}
      
      
      
      
      {/* <Customers /> */}
      <Connect />
    </>
  );
};
export default Home;
