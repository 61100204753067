import { FC, useState, useEffect } from "react";
import Heading from "components/Heading/Heading";
import CardLatestPost from "./CardLatestPost";
import AirTableBlog from "model/AirTableBlog";
import BlogPost from "interfaces/BlogPost";
export interface SectionLatestPostsProps {
  className?: string;
  postCardName?: "card3";
  cache?: any;
}
const SectionLatestPosts: FC<SectionLatestPostsProps> = ({
  postCardName = "cardLatestPost",
  className = "",
  cache,
}) => {
  const [posts, setPosts] = useState<BlogPost[]>();
  useEffect(() => {
    const getPosts = async () => {
      const _AirtableBlog = new AirTableBlog(undefined, "", undefined, cache);
      let posts: BlogPost[] = await _AirtableBlog.getPosts();
  
      setPosts(posts);
    };
    getPosts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`nc-SectionLatestPosts relative ${className}`}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-14">
          <Heading>Latest Articles 🎈</Heading>
          <div className={`grid gap-6 md:gap-8 grid-cols-1`}>
            {posts?.map((post, index) => (
              <CardLatestPost key={index} className="" post={post} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SectionLatestPosts;
