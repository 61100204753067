import { Helmet } from "react-helmet";
export interface PageTermsOfServiceProps {
  className?: string;
}

export default function TermsOfService({
  className = "",
}: PageTermsOfServiceProps) {
  return (
    <>
      <Helmet>
        <title>Terms of Service | Backpac</title>
        <meta name="description" content={"Backpac's Terms of Service"}></meta>
        <meta
          name="keywords"
          content={
            "Smart RPC Load Balancer pricing, price, subscription, Blockchain RPC Connectivity, Customizable Configurations, Load Balancing, High-Performance RPC, Reputable Providers, Fault Tolerance, Regional Load Balancing, Blockchain Network, API Key, Application Endpoint, Latency Optimization, Performance Enhancement, Custom Load Balancer Rules, RPC Method Routing, Block Height Routing, Smart Caching, Custom Target Groups, Top RPC Providers, Custom RPC Providers"
          }
        ></meta>
        <meta
          name="twitter:image"
          content="https://image.nft.backpac.xyz/crop.png"
        />
        <meta name="twitter:image:alt" content="Backpac's Terms of Service" />
        <meta
          property="og:image"
          content="https://image.nft.backpac.xyz/ICON_WHITE_1024_1024.jpg"
        />
        <meta property="og:image:alt" content="Backpac's Terms of Service" />
      </Helmet>
      <div
        className={`nc-TermsOfService container pb-24 lg:pb-32 ${className}`}
        data-nc-id="TermsOfService"
      >
        <div className="flex min-h-screen items-center justify-center">
          <section>
            <div className="mb-24 text-center">
              <h1 className="block antialiased font-sans relative my-5 text-center text-4xl font-bold leading-tight tracking-normal text-black md:text-5xl">
                {" "}
                Terms of Service{" "}
              </h1>
              <p className="block antialiased font-sans relative my-5 mx-auto text-center text-lg font-normal leading-relaxed tracking-normal text-600 md:text-xl lg:max-w-4xl">
                Backpac, Inc. and/or its affiliates ("Backpac," "we," "our," or
                "us") operate an online service ("Backpac.xyz") located at
                www.Backpac.xyz from which you may purchase products and
                services ("Backpac Products").
              </p>
            </div>
            <div className="grid grid-cols-12 ">
              <div className="col-span-12">
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  These Terms of Use apply to the content and functionality of
                  Backpac.xyz, and to the Backpac Products.
                </div>
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Information that you provide to us
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  The Customer, ("you") may give us information about yourself
                  when you visit Backpac.xyz. We may need to send you email and
                  text messages to, for example, verify your identity or provide
                  you with important information. You authorize us to send those
                  messages when you visit the Backpac.xyz platform and provide
                  your contact details. Standard text or data charges may apply
                  to text messages. Where offered, you may disable text message
                  notifications by responding to any such message with "STOP,"
                  or by following instructions provided in the message. However,
                  by disabling text messaging, you may be disabling important
                  security controls and may increase the risk of loss to your
                  services.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Backpac.xyz IP
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  As between you and Backpac, Backpac and its licensors
                  exclusively own all rights, title, and interest in the
                  patents, copyrights (including rights in derivative works),
                  moral rights, rights of publicity, trademarks or service
                  marks, logos and designs, trade secrets, and other
                  intellectual property embodied by, or contained in the
                  Backpac.xyz (collectively, "Backpac IP"). Backpac IP is
                  protected by copyright, trade secret, patent, and other
                  intellectual property laws, and all rights in Backpac IP not
                  expressly granted to you in these Terms of Use are reserved.
                  <br />
                  <br />
                  You may choose to or we may invite you to submit comments or
                  ideas about improvements to Backpac or our products or
                  services ("Ideas''). If you submit an Idea to us, we will
                  presume that your submission was voluntary, unsolicited by us,
                  and delivered to us without any restrictions on our use of the
                  Idea. You also agree that Backpac has no fiduciary or any
                  other obligation to you in connection with any Idea you submit
                  to us, and that we are free to use your Ideas without any
                  attribution or compensation to you.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Access to Backpac.xyz
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  Backpac grants you a limited, revocable, non-exclusive,
                  non-transferable license to access Backpac.xyz. This license
                  does not include a right to use any of the content and
                  information. Your permissions and/or licenses are
                  automatically terminated by any unauthorized use.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Products, Content, and Specifications
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  Details of the products and services available for purchase
                  from Backpac ("Backpac Products") are set out on the website
                  (www.Backpac.xyz). All features, content, specifications,
                  products, and prices of products and services described or
                  depicted in Backpac are subject to change at any time without
                  notice. Unless expressly noted, all weights, measures, and
                  similar descriptions are approximate and are provided for
                  convenience purposes only. The inclusion of any products or
                  services in Backpac.xyz at a particular time does not imply or
                  warrant that these products or services will be available at
                  any time. Occasionally, the delivery of a certain product or
                  service may be delayed for a number of reasons. In such an
                  event, we will make reasonable efforts to notify you of the
                  delay and keep you informed of the revised delivery schedule.
                  By placing an order, you represent that the products ordered
                  will be used only in a lawful manner.
                  <br />
                  <br />
                  Included Responses and Definitions: Included Responses are any
                  responses produced from making a request to the node using
                  HTTP or Websocket endpoints. Only responses with http return
                  status 200 are counted.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Subscriptions
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  <ol className="list-inside list-decimal hover:list-disc">
                    <li className="my-5">
                      Monthly subscription terms. We may offer you the ability
                      to purchase monthly subscriptions that contain a fixed
                      rate limit via Backpac.com. Terms specific to a
                      subscription will be disclosed to you at or prior to the
                      time at which you purchase the subscription, and by
                      purchasing the subscription, you are agreeing to those
                      terms.
                    </li>
                    <li className="my-5">
                      Free trials and promotional periods. If a subscription
                      commences with a free trial or a promotional period, you
                      will have the right to cancel the subscription prior to
                      the end of the trial or period. If we do not provide you
                      with an online cancellation mechanism, then you may
                      exercise this cancellation right by contacting us
                      (support@backpac.xyz). If you do not cancel, we will bill
                      you at the end of the free trial or promotional period,
                      and your subsequent cancellation rights will be in
                      accordance with the terms specific to the subscription.
                    </li>
                    <li className="my-5">
                      Cancellation. Your subscriptions for Velocity, Throughput and High Throughput plans
                      shall continue on a rolling monthly basis until you
                      cancel. You have the right to cancel your subscription
                      anytime, and it's effective at the end of the month. 
                    </li>
                  </ol>
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Your account
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  We may require that you create an account to access
                  Backpac.xyz, including to make purchases. If we do so, you
                  must provide accurate information about yourself when you
                  create an account and ensure that you update us if that
                  information changes. You must ensure that your login details
                  remain confidential. You are responsible for any activity,
                  including any purchases made, under your account. In our sole
                  discretion, we reserve the right to terminate your account at
                  any time and for any reason.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Customer restrictions and responsibilities
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  <ol className="list-inside list-decimal">
                    <li className="my-5">
                      By agreeing to the use of Backpac Products and
                      Backpac.xyz, you will: (i) be solely responsible for all
                      the use of Backpac Products under your account; (ii) use
                      Backpac Products only in accordance with these terms
                      herein in the Terms of Service, relevant documents, and
                      with any applicable laws and regulations; (iii) prevent
                      any unauthorized access from our Products; (iv) use
                      commercially reasonable efforts to prevent unauthorized
                      access to or use of Backpac Products and notify Backpac
                      immediately of unauthorized access or use; (v) not
                      sublicense, lease, sell, resell, rent, loan, distribute,
                      transfer or otherwise allow the use of Backpac Products or
                      any services on Backpac.com for the benefit of third party
                      (vi) not intentionally interfere in any manner with the
                      operation of the Backpac Products or the hardware and
                      network used to operate Backpac.com and Backpac Products;
                      (vii) not intentionally reverse engineer, decompile,
                      disassemble, or otherwise derive or determine or attempt
                      to derive or determine the source code (or the underlying
                      ideas, algorithms, structure, or organization) of
                      Backpac.com or the Backpac platform; and (viii) not
                      modify, copy or make derivative works
                    </li>
                    <li className="my-5">
                      Further, Customer may not remove or export from the United
                      States or allow the export or re-export of the Services,
                      Software or anything related thereto, or any direct
                      product thereof in violation of any restrictions, laws or
                      regulations of the United States Department of Commerce,
                      the United States Department of Treasury Office of Foreign
                      Assets Control, or any other United States or foreign
                      agency or authority. As defined in FAR section 2.101, the
                      Software and documentation are “commercial items” and
                      according to DFAR section 252.227 7014(a)(1) and (5) are
                      deemed to be “commercial computer software” and
                      “commercial computer software documentation.” Consistent
                      with DFAR section 227.7202 and FAR section 12.212, any use
                      modification, reproduction, release, performance, display,
                      or disclosure of such commercial software or commercial
                      software documentation by the U.S. Government will be
                      governed solely by the terms of this Agreement and will be
                      prohibited except to the extent expressly permitted by the
                      terms of this Agreement
                    </li>
                    <li className="my-5">
                      Customer shall be responsible for obtaining and
                      maintaining any equipment and ancillary services needed to
                      connect to, access or otherwise use the Services,
                      including, without limitation, RPC Node Providers,
                      Blockchain Nodes, hardware, servers, software, operating
                      systems, networking, web servers and the like
                      (collectively, “Equipment”). Customer shall also be
                      responsible for maintaining the security of the Equipment,
                      Customer account, passwords (including but not limited to
                      administrative and user passwords) and files, and for all
                      uses of Customer account or the Equipment with or without
                      Customer’s knowledge or consent.
                    </li>
                    <li className="my-5">
                      Customer will not, directly or indirectly: reverse
                      engineer, decompile, disassemble or otherwise attempt to
                      discover the source code, object code or underlying
                      structure, ideas, know-how or algorithms relevant to the
                      Services or any software, documentation or data related to
                      the Services (“Software”); modify, translate, or create
                      derivative works based on the Services or any Software
                      (except to the extent expressly permitted by Company or
                      authorized within the Services); use the Services or any
                      Software for timesharing or service bureau purposes or
                      otherwise for the benefit of a third; or remove any
                      proprietary notices or labels.
                    </li>
                  </ol>
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Billing
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  Service terms begin with the date you elect your plan and
                  automatically renew every month until you cancel it. Overage
                  usage will be billed at the overage rate throughout the term
                  of your service. All overage charges are your responsibility.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Refunds
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  Unless we tell you otherwise, you will only be entitled to a
                  refund if we provide you with a service that does not match
                  the product description of the service that you purchased. If
                  that occurs, your exclusive remedy is to contact us for a
                  refund. To begin the return process, please contact us.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Sanctions and export policy
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  You may not use Backpac.xyz or purchase any Backpac service in
                  or for the benefit of a country, organization, entity, or
                  person embargoed or blocked by any government, including those
                  on sanctions lists identified by the United States Office of
                  Foreign Asset Control (OFAC). We do not claim, and we cannot
                  guarantee that the Backpac.xyz or any Backpac Product is or
                  will be appropriate or available for any location or
                  jurisdiction, comply with the laws of any location or
                  jurisdiction, or comply with laws governing export, import, or
                  foreign use.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  No warranties
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  We provide Backpac.xyz, Backpac IP and Backpac.xyz Products
                  "as is" and "as available", without any express, implied, or
                  statutory warranties of title, merchantability, fitness for a
                  particular purpose, noninfringement, or any other type of
                  condition, warranty or guarantee. No data, documentation or
                  any other information provided by Backpac or obtained by you
                  from or through Backpac.xyz – whether from Backpac or another
                  entity, and whether oral or written – creates or implies any
                  warranty from Backpac to you.
                  <br />
                  <br />
                  Backpac disclaims any knowledge of, and does not guarantee:
                  (a) the accuracy, reliability, or correctness of any data
                  provided through the Backpac.xyz; (b) that the Backpac.xyz
                  Products will meet your specific needs or requirements; (c)
                  that the Backpac.xyz will be available at any particular time
                  or location, or will function in an uninterrupted manner or be
                  secure; (d) that Backpac will correct any defects or errors in
                  the Backpac.xyz; or (e) that the Backpac.xyz is free of
                  viruses or other harmful code. Use of data, products or
                  services that you access, purchase or download through
                  Backpac.xyz is done at your own risk – you are solely
                  responsible for any damage to your property, loss of data, or
                  any other loss that results from such access, purchase or
                  download.
                  <br />
                  <br />
                  Nothing in these Terms of Use operates to exclude, restrict or
                  modify the application of any implied condition, warranty or
                  guarantee, or the exercise of any right or remedy, or the
                  imposition of any liability under law to the extent that doing
                  so would: (a) contravene that law; or (b) cause any term of
                  this agreement to be void.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Limitation of liability
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  Under no circumstances will Backpac be responsible or liable
                  to you for any indirect, punitive, incidental, special,
                  consequential, or exemplary damages resulting from your use or
                  inability to use Backpac.xyz or for the unavailability of
                  Backpac.xyz, or for lost profits, personal injury, or property
                  damage, or for any other damages arising out of, in connection
                  with, or relating to these Terms of Use or your use of
                  Backpac.xyz, even if such damages are foreseeable, and whether
                  or not you or Backpac has been advised of the possibility of
                  such damages. Backpac is not liable, and denies responsibility
                  for, any damages, harm, or losses to you arising from or
                  relating to hacking, tampering, or other unauthorized access
                  or use of Backpac.xyz or your failure to use or implement
                  anti-fraud measures, security controls, or any other data
                  security measure. Backpac further denies responsibility for
                  all liability and damages to you or others caused by (a) your
                  access or use of Backpac.xyz inconsistent with our
                  instructions; (b) any unauthorized access of servers,
                  infrastructure, or data used in connection with Backpac.xyz;
                  (c) any bugs, viruses, or other harmful code that may be
                  transmitted to or through Backpac.xyz; (d) any errors,
                  inaccuracies, omissions, or losses in or to any data provided
                  to us; (e) third-party content provided by you; or (f) the
                  defamatory, offensive, or illegal conduct of others.
                  <br />
                  <br />
                  You agree to limit any additional liability not disclaimed or
                  denied by Backpac in relation to Backpac.xyz, Backpac IP, and
                  Backpac.xyz Products, to your direct and documented damages;
                  and you further agree that under no circumstances will any
                  such liability exceed in the aggregate the greater of the
                  amounts paid by you to Backpac during the three-month period
                  immediately preceding the event that gave rise to your claim
                  for damages, and USD $20.
                  <br />
                  <br />
                  Notwithstanding the foregoing, you agreed by using
                  Backpac.xyz, Backpac IP, and Backpac.xyz Products, you have
                  unlimited liability for gross negligence, willful misconduct,
                  fraud, or misrepresentation.
                  <br />
                  <br />
                  These limitations on our liability to you will apply
                  regardless of the legal theory on which your claim is based,
                  including contract, tort (including negligence), strict
                  liability, or any other theory or basis.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Applicable law
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  By using Backpac.xyz, you agree that the laws of the state of
                  New York, USA, Except as subject to resolution, any claim,
                  suit, action, suit, acting or proceeding hereunder must be
                  brought in the appropriate state or federal court located in
                  the borough of Manhattan, New York City, New York, without
                  regard to principles of conflict of laws, will govern these
                  Terms of Use and any dispute of any sort that might arise
                  between you and Backpac.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Modification and severability
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  We have the right to change or add to the terms of these Terms
                  of Use at any time, solely with prospective effect, and to
                  change, delete, discontinue, or impose conditions on use of
                  Backpac.xyz by posting such changes on our website or any
                  other website we maintain or own. You can access a copy of the
                  current version of these Terms of Use on our website at any
                  time. You can find out when these Terms of Use were last
                  changed by checking the "Last updated" date at the top of the
                  page. Your continued use of our services constitutes your
                  acceptance of updated terms.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Testimonial release
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  You agree to allow Backpac to use your company name, logo, and
                  testimonial as per the the terms set out here:&nbsp;
                  <a href="/testimonial">https://www.Backpac.xyz/testimonial</a>
                  .
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Customer Service
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  Our primary objective is to ensure our customers receive
                  exceptional service and an optimal availability for all
                  Backpac plans.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Services and Support
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  Subject to the terms of this Agreement, Company will use
                  commercially reasonable efforts to provide Customer the
                  Services. As part of the registration process, Customer will
                  identify an administrative email address and password for
                  Customer’s Company account. Company reserves the right to
                  refuse registration of, or cancel passwords it deems
                  inappropriate.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Restrictions
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  Due to U.S. sanctions, we do not allow customers with I.P.
                  addresses originating in the following regions or countries to
                  create accounts or boot endpoints: Cuba, North Korea, Iran,
                  Syria or Crimea.
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
