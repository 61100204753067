import { useEffect  } from "react";
import { Helmet } from "react-helmet";
import Providers from "containers/PageHome/sections/Providers";
import Hero from "./sections/Hero";
import QuickNode from "containers/PageHome/sections/Quicknode";
import Enterprise from "containers/PageHome/sections/Enterprise";
import Benefits from "./sections/Benefits";
const Polygon = () => {
  useEffect(() => {
  }, []);

  return (
    <>
      <Helmet>
        <title>
         Polygon RPC Request Throughput | Mainnet | Goerli | Sepolia
        </title>
        <meta
          name="description"
          content={
            "Boost the throughput of your Polygon RPC request effortlessly and benefit from intelligent distribution across Polygon RPC nodes, coupled with rate limit optimization, for substantial increase in throughput to the Polygon Blockchain."
          }
        ></meta>
        <meta
          name="keywords"
          content={
            "Polygon MATIC RPC providers, Web3 redundancy, QuickNode Marketplace Add-On, Blockchain access, Multi-network connectivity, Polygon Mainnet, Polygon Goerli testnet, Polygon Sepolia testnet, Streamlined application development, Decentralized applications"
          }
        ></meta>
                        <meta
          name="twitter:image"
          content="https://image.nft.backpac.xyz/crop.png"
        />
        <meta
          name="twitter:image:alt"
          content="Backpac's Polygon RPC Load Balancer"
        />
        <meta
          property="og:image"
          content="https://image.nft.backpac.xyz/ICON_WHITE_1024_1024.jpg"
        />
        <meta
          property="og:image:alt"
          content="Backpac's Polygon RPC Load Balancer"
        />
      </Helmet>
      <Hero />
      <Benefits/>
      {/* <EthereumResources /> */}
      <Providers />
      <QuickNode />
      <Enterprise />
    </>
  );
};
export default Polygon;
