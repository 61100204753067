import React, { FC } from "react";
import MainNavEmpty from "./MainNavEmpty";

export interface HeaderEmptyProps {}

const HeaderEmpty: FC<HeaderEmptyProps> = () => {
  return (
    <div className="nc-Header2 relative w-full z-40 ">
      <MainNavEmpty />
    </div>
  );
};

export default HeaderEmpty;
