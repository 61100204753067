import { Helmet } from "react-helmet";
export interface PagePrivacyProps {
  className?: string;
}

export default function Privacy({
  className = "",
}: PagePrivacyProps) {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Backpac</title>
        <meta name="description" content={"Backpac's Privacy Policy"}></meta>
        <meta
          name="keywords"
          content={
            "Privacy Policy, Smart RPC Load Balancer pricing, price, subscription, Blockchain RPC Connectivity, Customizable Configurations, Load Balancing, High-Performance RPC, Reputable Providers, Fault Tolerance, Regional Load Balancing, Blockchain Network, API Key, Application Endpoint, Latency Optimization, Performance Enhancement, Custom Load Balancer Rules, RPC Method Routing, Block Height Routing, Smart Caching, Custom Target Groups, Top RPC Providers, Custom RPC Providers"
          }
        ></meta>
        <meta
          name="twitter:image"
          content="https://image.nft.backpac.xyz/crop.png"
        />
        <meta name="twitter:image:alt" content="Backpac's Privacy Policy" />
        <meta
          property="og:image"
          content="https://image.nft.backpac.xyz/ICON_WHITE_1024_1024.jpg"
        />
        <meta property="og:image:alt" content="Backpac's Privacy Policy" />
      </Helmet>
      <div
        className={`nc-Privacy container pb-24 lg:pb-32 ${className}`}
        data-nc-id="Privacy"
      >
        <div className="flex min-h-screen items-center justify-center">
          <section>
            <div className="mb-24 text-center">
              <h1 className="block antialiased font-sans relative my-5 text-center text-4xl font-bold leading-tight tracking-normal text-black md:text-5xl">
                {" "}
                Privacy Policy{" "}
              </h1>
              <p className="block antialiased font-sans relative my-5 mx-auto text-center text-lg font-normal leading-relaxed tracking-normal text-600 md:text-xl lg:max-w-4xl">
                Protecting your private information is our priority. This
                Privacy Policy applies to Backpac.xyz and Backpac, Inc. and
                governs data collection and usage. For the purposes of this
                Privacy Policy, unless otherwise noted, all references to
                Backpac Inc. include Backpac.xyz and Backpac (“we” “our” or
                “us”). The Backpac website is a blockchain infrastructure
                as-a-service site. Your consent is important to us. By using the
                Backpac website (“Site”), you consent to the data practices
                described in this policy.
              </p>
            </div>
            <div className="grid grid-cols-12 ">
              <div className="col-span-12">
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Collection of your Personal Information
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  In order to better provide you with products and services
                  offered on our Site, Backpac may collect personally
                  identifiable information, such as your first name, last name,
                  e-mail address, and job title. If you purchase Backpac’s
                  products and services, we collect billing and credit card
                  information as well. This information is used to complete the
                  purchase transaction. Backpac may also collect anonymous
                  demographic information, which is not unique to you, such as
                  your age, gender, and location. Please keep in mind that if
                  you directly disclose personally identifiable information or
                  personally sensitive data through Backpac’s public message
                  boards, this information may be collected and used by others.
                  <br />
                  <br />
                  We do not collect any personal information about you unless
                  you voluntarily provide it to us. However, you may be required
                  to provide certain personal information to us when you elect
                  to use certain products or services available on the Site.
                  These may include: (a) registering for an account on our Site;
                  (b) entering an event or contest sponsored by us or one of our
                  partners; (c) signing up for special offers from selected
                  third parties; (d) sending us an email message; (e) submitting
                  your credit card or other payment information when ordering
                  and purchasing products and services on our Site. To wit, we
                  will use your information for, but not limited to,
                  communicating with you in relation to services and/or products
                  you have requested from us. We also may gather additional
                  personal or non-personal information in the future.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Use of your Personal Information
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  Backpac collects and utilizes your personal information to
                  ensure the operation of its website(s) and deliver the
                  services you have requested. We may use your personally
                  identifiable information to communicate with you, respond to
                  your inquiries, and provide customer support.
                  <br />
                  <br />
                  Additionally, Backpac may use your information to personalize
                  your experience, tailor content and offers to your interests,
                  and improve our services. Backpac may also use your personal
                  information for internal purposes such as data analysis,
                  research, and enhancing our website functionality.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Information Disclosed by Backpac (CCPA and GDPR compliance do
                  not sell information)
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  Backpac does not sell your personal information. Your privacy
                  is important to us, and we are committed to protecting your
                  data. We use your information only for the purposes stated in
                  our Privacy Policy and do not engage in selling or trading
                  your personal data to third parties.
                  <br />
                  <br />
                  Backpac may disclose personal information with trusted service
                  providers for various business and product operations
                  purposes, including but not limited to, customer support
                  services, website debugging, troubleshooting, product
                  development, security purposes, research and analysis,
                  marketing and promotional communications, payment processing,
                  billing, collections, and professional services (e.g.,
                  auditors, insurance providers, financial service providers,
                  legal advisors, etc.). Additionally, we may store your data on
                  servers provided by third-party hosting vendors with whom we
                  have contracted. All such third-parties are prohibited from
                  using your personal information except to provide services to
                  Backpac, and they are required to maintain the confidentiality
                  of your information.
                  <br />
                  <br />
                  Backpac may disclose your personal information, without
                  notice, if required to do so or in the good faith belief that
                  such action is necessary in the following situation, but not
                  limited to: (a) conform to the edicts of the law or comply
                  with legal process served on Backpac or the Site; (b) protect
                  and defend the rights or property of Backpac; (c) act under
                  exigent circumstances to protect the personal safety of users
                  of Backpac, or the public (d) In the event of a sale,
                  transfer, merger, acquisition, or other change of control,
                  your information may be transferred to relevant parties and/or
                  (d) you consent that Backpac may disclose your personal
                  information.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Tracking User Behavior
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  Backpac may keep track of the websites and pages our users
                  visit within Backpac, to gain insights and improve our
                  services. This data is used to deliver customized content and
                  advertising within Backpac to customers whose behavior
                  indicates that they are interested in a particular subject
                  area.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Automatically Collected Information
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  Information about your computer hardware and software may be
                  automatically collected by Backpac. This information can
                  include such details as your IP address, browser type, domain
                  names, access times and referring website addresses. We
                  collect this information to ensure the smooth operation of our
                  services, to maintain quality of the service, enhance user
                  experience, personalize content, and optimize our website
                  functionality.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Use of Cookies
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  The Backpac website may use "cookies" and similar tracking
                  technologies on our website to enhance your browsing
                  experience and provide you with personalized services. A
                  cookie is a text file that is placed and stored on your device
                  when you visit our website. Cookies cannot be used to run
                  programs or deliver viruses to your computer. Cookies are
                  uniquely assigned to you and can only be read by a web server
                  in the domain that issued the cookie to you. By using our
                  website, you consent to the use of cookies as described
                  herein. You have the option to manage or disable cookies
                  through your browser settings. One of the primary purposes of
                  cookies is to provide a convenience feature to save you time.
                  The purpose of a cookie is to tell the Web server that you
                  have returned to a specific page. For example, if you
                  personalize Backpac pages, or register with Backpac site or
                  services, a cookie helps Backpac to recall your specific
                  information on subsequent visits. This simplifies the process
                  of recording your personal information, such as billing
                  addresses, shipping addresses, and so on. When you return to
                  the same Backpac website, the information you previously
                  provided can be retrieved, so you can easily use the Backpac
                  features that you customized. We may also use cookies for
                  targeted advertising purposes. You have the ability to accept
                  or decline cookies. Most Web browsers automatically accept
                  cookies, but you can usually modify your browser setting to
                  decline cookies if you prefer. If you choose to decline
                  cookies, you may not be able to fully experience the
                  interactive features of the Backpac services or websites you
                  visit.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Links
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  This website contains links to other sites. Please be aware
                  that we are not responsible for the content or privacy
                  practices of such other sites. We encourage our users to be
                  aware when they leave our Site and to read the privacy
                  statements of any other site that collects personally
                  identifiable information.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Security of your Personal Information
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  Security is a critical priority for us. Backpac is committed
                  to ensuring the security of your personal information. We
                  employ industry-standard technical and organizational measures
                  to protect your data against unauthorized access, alteration,
                  disclosure, or destruction. Our security practices include the
                  use of encryption, firewalls, access controls, and regular
                  system monitoring. We regularly review and enhance our
                  security measures to mitigate risks and safeguard your
                  personal information.
                  <br />
                  <br />
                  We strive to take appropriate security measures to protect
                  against unauthorized access to or alteration of your personal
                  information. Unfortunately, no data transmission over the
                  Internet or any wireless network can be guaranteed to be 100%
                  secure. As a result, while we strive to protect your personal
                  information, you acknowledge that: (a) there are security and
                  privacy limitations inherent to the Internet which are beyond
                  our control; and (b) security, integrity, and privacy of any
                  and all information and data transmissions cannot be
                  guaranteed.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Children Under Thirteen
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  Backpac does not knowingly collect personally identifiable
                  information from children under the age of thirteen. If you
                  are under the age of thirteen, you must ask your parent or
                  guardian for permission to use this website.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  GDPR and International Data Transfers
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  Should you live in the European Union or the United Kingdom,
                  we may need to send your personal information to other
                  countries. This means that your data could be processed in a
                  country with different privacy rules than where you live. When
                  we do this, we take steps to make sure your information is
                  protected and that the transfer follows the laws that apply to
                  your data. To keep your personal information safe, we might
                  use legal agreements, like Standard Contractual Clauses to
                  provide an adequate level of protection, to make sure your
                  data gets the same level of protection as it would in your
                  country.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Your rights
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  You have certain rights regarding your personal data, namely.
                  <br />
                  <ol className="list-inside list-disc">
                    <li className="my-5">
                      Access. The right to obtain information about how their
                      personal data is being processed.
                    </li>
                    <li className="my-5">
                      Rectification. The right to request correction of
                      inaccurate or incomplete personal data.
                    </li>
                    <li className="my-5">
                      Erasure (Right to be Forgotten). The right to request
                      deletion of personal data under certain circumstances.
                    </li>
                    <li className="my-5">
                      Restriction of Processing. The right to request limitation
                      on the processing of their personal data.
                    </li>
                    <li className="my-5">
                      Data Portability. The right to receive personal data in a
                      portable format and, in some cases, transmit it to another
                      controller.
                    </li>
                    <li className="my-5">
                      Objection. The right to object to the processing of
                      personal data based on legitimate interests or for direct
                      marketing purposes.
                    </li>
                    <li className="my-5">
                      Automated Decision Making and Profiling. The right not to
                      be subject to decisions based solely on automated
                      processing, including profiling.
                    </li>
                    <li className="my-5">
                      Withdrawal of Consent. The right to withdraw consent to
                      the processing of personal data, if processing is based on
                      consent.
                    </li>
                  </ol>
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Data Retention
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  We retain your personal information for as long as necessary
                  to fulfill the purposes outlined in this Privacy Policy,
                  unless a longer retention period is required or permitted by
                  law. In general, we keep your information for a period of
                  seven years. This ensures compliance with legal obligations,
                  resolution of any disputes, and the establishment, exercise,
                  or defense of legal claims. After the retention period, we
                  securely delete or anonymize your data. Please note that
                  certain data may be retained for a longer period if required
                  by applicable laws or regulations.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  E-mail Communications
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  From time to time, Backpac may contact you via email for the
                  purpose of providing announcements, promotional offers,
                  alerts, confirmations, surveys, and/or other general
                  communication. In order to improve our Services, we may
                  receive a notification when you open an email from Backpac or
                  click on a link therein.
                  <br />
                  <br />
                  If you would like to stop receiving marketing or promotional
                  communications via email from Backpac, you may opt out of such
                  communications by clicking Unsubscribe at the bottom of the
                  email.
                </div>
                <hr className="my-6 border-blue-gray-50" />
                <h2 className="block antialiased tracking-normal font-sans text-xl leading-snug text-inherit mt-6 mb-1 font-semibold !text-black">
                  Changes to this Statement
                </h2>
                <div className="block antialiased font-sans text-base leading-relaxed mb-4 font-normal text-600">
                  Backpac reserves the right to change this Privacy Policy
                  from time to time. We will notify you about significant
                  changes in the way we treat personal information by sending a
                  notice to the primary email address specified in your account,
                  by placing a prominent notice on our site, and/or by updating
                  any privacy information on this page. Your continued use of
                  the Site and/or Services available through this Site after
                  such modifications will constitute your: (a) acknowledgment of
                  the modified Privacy Policy; and (b) agreement to abide and be
                  bound by that Policy.
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
