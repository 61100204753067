//import Flow from "./flow/Flow";
import { useEffect  } from "react";
import { Helmet } from "react-helmet";
import EthereumResources from "./sections/EthereumResources";
//import ProviderLogoCloud from "./sections/ProviderLogoCloud";
import Providers from "containers/PageHome/sections/Providers";
import Hero from "./sections/Hero";
import QuickNode from "containers/PageHome/sections/Quicknode";
import Enterprise from "containers/PageHome/sections/Enterprise";
import Benefits from "./sections/Benefits";
const Ethereum = () => {
  useEffect(() => {


  }, []);


  return (
    <>
      <Helmet>
        <title>
         Ethereum RPC Load Balancer | Mainnet | Goerli | Sepolia
        </title>
        <meta
          name="description"
          content={
            "Explore Backpac's seamless Ethereum blockchain connectivity with a single endpoint and multiple RPC providers. Ensure web3 redundancy and scalability effortlessly."
          }
        ></meta>
        <meta
          name="keywords"
          content={
            "Ethereum RPC providers, Web3 redundancy, QuickNode Marketplace Add-On, Blockchain access, Multi-network connectivity, Ethereum Mainnet, Goerli testnet, Sepolia testnet, Streamlined application development, Decentralized applications"
          }
        ></meta>
      </Helmet>
      <Hero />
      <Benefits/>
      <EthereumResources />
      <Providers />
      <QuickNode />
      <Enterprise />
    </>
  );
};
export default Ethereum;
