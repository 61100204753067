import { Magic } from 'magic-sdk';

const magic = new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY);


export const checkUser = async (cb) => {
  const isLoggedIn = await magic.user.isLoggedIn();
  //console.log(isLoggedIn);
  if (isLoggedIn) {
    const user = await magic.user.getMetadata();
    return cb({ isLoggedIn: true, email: user.email, user });
  }
  return cb({ isLoggedIn: false });
};

export const loginUser = async (email) => {
  await magic.auth.loginWithMagicLink({ email });
};

export const logoutUser = async () => {
  await magic.user.logout();
};

export const getIdToken = async () => {
  return await magic.user.getIdToken()
};
export const getMetadata = async () => {
  return await magic.user.getMetadata()
};