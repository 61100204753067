import ETH from "../../../images/logo/ethereum-2.png";
import BASE from "../../../images/logo/base.png";
import MATIC from "../../../images/logo/polygon.png";
import SOL from "../../../images/logo/solana.png";
import GNOSIS from "../../../images/logo/gnosis.png"
import BSC from "../../../images/logo/bsc.png";
import OPT from "images/logo/optimism.png";
import AVAX from "images/logo/avalanche.png";
import ARB from "images/logo/arbitrum.png";
const Networks = () => {

    const stats = [
        {
            data: ETH,
            title: "Ethereum",
            alt: 'Ethereum Blockchain (Ethereum)'
        },
        {
            data: BASE,
            title: "Base",
            alt: 'Coinbase BASE Blockchain (Base)'
        },
        {
            data: MATIC,
            title: "Polygon",
            alt: 'Polygon MATIC Blockchain (Polygon)'
        },
        {
            data: SOL,
            title: "Solana",
            alt: 'Solana Blockchain (Solana)'
        },
        {
            data: GNOSIS,
            title: "Gnosis",
            alt: 'Gnosis Blockchain (Gnosis)'
        },        
        {
            data: BSC,
            title: "BSC",
            alt: 'Binance Smart Chain (BSC)'
        },
        {
            data: OPT,
            title: "Optimism",
            alt: 'Optimism Blockchain (Optimism)'
        },
        {
            data: AVAX,
            title: "Avalanche",
            alt: 'Avalanche Blockchain (Avalanche)'
        },        
        {
            data: ARB,
            title: "ARB",
            alt: 'Arbitrum Smart Chain (Arbitrum)'
        },
    ]

    return (
        <section className="py-14">
            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                <div className="max-w-2xl mx-auto text-center">
                    <h3 className="text-3xl font-semibold text-neutral-900 dark:text-neutral-100 text-3xl xl:text-4xl">
                    Multiple Blockchain Networks Supported
                    </h3>
                    <p className="mt-3 text-neutral-700 dark:text-neutral-300">
                    Backpac comprehensively addresses your blockchain network access requirements, whether it's Mainnet, Testnet, or Devnet – we've got you covered.
                    </p>
                </div>
                <div className="mt-12">
                    <ul className="flex flex-col items-center justify-center gap-y-10 sm:flex-row sm:flex-wrap lg:divide-x">
                        {
                            stats.map((item, idx) => (
                                <li key={idx} className="flex flex-col items-center text-center px-12 md:px-16">
                                    <img src={item.data} alt={item.alt} className="object-cover object-center rounded w-10 h-10"/>
                                    <p className="mt-3 font-small text-neutral-700 dark:text-neutral-300">{item.title}</p>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Networks;