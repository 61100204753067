import Airtable from "airtable";
import BlogPost from "interfaces/BlogPost";
export default class AirTableBlog {
  readonly apiKey: string | undefined;
  readonly base: string | "";
  readonly table: string | undefined;
  readonly _table: any | undefined;
  readonly _Airtable: Airtable;
  readonly _AirtableBase: any;
  readonly cache: any | undefined;

  constructor(
    ...args: []
      | [apiKey: string | undefined, base: string, table: string | undefined, cache: any | undefined]
  ) {
    this.apiKey = args[0] || process.env.REACT_APP_AIRTABLE_KEY;
    this.base = args[1] || process.env.REACT_APP_AIRTABLE_BASE || "";
    this.table = args[2] || process.env.REACT_APP_AIRTABLE_TABLE;
    this._Airtable = new Airtable({ apiKey: this.apiKey });
    this._AirtableBase = this._Airtable.base(this.base);
    this._table = this._AirtableBase.table(this.table);
    this.cache = args[3] || undefined;
  }
  getFeaturedPosts = async ():  Promise<BlogPost[]> => {
    let posts = this.cache.get("blog-featured-posts") as BlogPost[] | undefined;
  
    if (!posts) {
      try {
        const response = await this._table?.select({
          sort: [{ field: 'date', direction: 'desc' }],
          filterByFormula: 'AND({isFeatured}="true", {status}="published")'
        }).all();
  
        if (response) {
          posts = response.map((post: any) => ({
            id: post.id,
            title: post.fields.title,
            content: post.fields.content,
            date: post.fields.date,
            status: post.fields.status,
            author: post.fields.author?.name || '',
            avatar: post.fields.avatar?.[0]?.url || null,
            slug: post.fields.slug,
            featured: post.fields.featured?.[0]?.url || null,
            isFeatured: post.fields.isFeatured,
            description: post.fields.description,
            tag: post.fields.tag,
            metaDescription: post.fields.metaDescription,
            metaKeywords: post.fields.metaKeywords
          }));
  
          this.cache.set("blog-featured-posts", posts);
        }
      } catch (error) {
        console.error('Error fetching featured blog posts:', error);
        return [];
      }
    }
  
    return posts || [];
  };
  
  getPosts = async ():  Promise<BlogPost[]>  => {

    let posts: BlogPost[] = this.cache.get(`blog-posts`);

    if(typeof posts === "undefined"){
      posts = await this._table?.select({sort: [{field: 'date', direction: 'desc'}] ,filterByFormula: 'AND({isFeatured}="false", {status}="published")'}).all();
      this.cache.set(`blog-posts`, posts);
    }
   
    const posts_ = posts.map((post: any) => {

      const BlogPost: BlogPost = {
        id: post.id,
        title: post.fields.title,
        content: post.fields.content,
        date: post.fields.date,
        status: post.fields.status,
        author: post.fields.author.name,
        avatar: post.fields.avatar,
        slug: post.fields.slug,
        featured: post.fields.featured,
        isFeatured: post.fields.isFeatured,
        description: post.fields.description,
        tag: post.fields.tag,
        metaDescription: post.fields.metaDescription,
        metaKeywords: post.fields.metaKeywords
      }

      return BlogPost;
    });

    return posts_;
  };
  getPost = async (slug: string | undefined): Promise<BlogPost> => {
    if (!slug) {
      throw new Error("Invalid slug");
    }
  
    const cachedPost = this.cache.get(`blog-post-${slug}`) as BlogPost | undefined;
  
    if (cachedPost) {
      return cachedPost;
    }
  
    try {
      const posts = await this._table
        ?.select({
          filterByFormula: `AND({slug}="${slug}", {status}="published")`
        })
        .all();
  
      if (posts && posts.length > 0) {
        const post = posts[0];
        const BlogPost: BlogPost = {
          id: post.id,
          title: post.fields.title,
          content: post.fields.content,
          date: post.fields.date,
          status: post.fields.status,
          author: post.fields.author?.name || "",
          avatar: post.fields.avatar?.[0]?.url || null,
          slug: post.fields.slug,
          featured: post.fields.featured?.[0]?.url || null,
          isFeatured: post.fields.isFeatured || false,
          description: post.fields.description,
          tag: post.fields.tag,
          metaDescription: post.fields.metaDescription,
          metaKeywords: post.fields.metaKeywords
        };
  
        this.cache.set(`blog-post-${slug}`, BlogPost, 720);
        return BlogPost;
      }
    } catch (error) {
      console.error("Error fetching blog post:", error);
      throw new Error("Error fetching blog post");
    }
  
    throw new Error("No blog post found");
  };
}
