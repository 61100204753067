import QuickNode from "images/providers/quicknode.png";
import Alchemy from "images/providers/alchemy.png";
import Blockdaemon from "images/providers/blockdaemon.png";
import Chainstack from "images/providers/chainstack.png";
import Ankr from "images/providers/ankr.png";
import Helius from "images/providers/helius.png";

export interface ChainInfo {
  name: string;
  role: string;
  href: string;
  imageUrl: string;
  addOnHref?: string;
  addOnText?: string;
}

export interface Chains {
  [key: string]: ChainInfo[];
}

export const Blockchains: Chains = {
  solana: [
    {
      name: "QuickNode",
      role: "Mainnet, Testnet, Devnet",
      href: "https://www.quicknode.com/chains/sol",
      imageUrl: QuickNode,
      addOnHref:
        "https://marketplace.quicknode.com/add-on/smart-rpc-load-balancer",
      addOnText:
        "Click to install Backpac's Add On from the QuickNode Marketplace",
    },
    {
      name: "Chainstack",
      role: "Mainnet, Testnet, Devnet",
      href: "https://chainstack.com/build-better-with-solana/",
      imageUrl: Chainstack,
    },
    {
      name: "Helius",
      role: "Mainnet, Testnet, Devnet",
      href: "https://www.helius.dev/",
      imageUrl: Helius,
    },
    {
      name: "Alchemy",
      role: "Mainnet, Devnet",
      href: "https://www.alchemy.com/solana",
      imageUrl: Alchemy,
    },
    {
      name: "Ankr",
      role: "Mainnet, Testnet, Devnet",
      href: "https://www.ankr.com/rpc/solana/",
      imageUrl: Ankr,
    },
    {
      name: "Blockdaemon",
      role: "Mainnet, Testnet, Devnet",
      href: "https://www.blockdaemon.com/protocols/solana",
      imageUrl: Blockdaemon,
    },
  ],
};
