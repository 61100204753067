import { FC, useState, useEffect } from "react";
import CardFeaturedPost from "./CardFeaturedPost";
import CardPost from "./CardPost";
import AirTableBlog from "model/AirTableBlog";
import BlogPost from "interfaces/BlogPost";
export interface SectionMagazineProps {
 cache: any
}

const SectionMagazine: FC<SectionMagazineProps> = ({cache}) => {

  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [featured, setFeatured] = useState({});

  useEffect(() => {
    const getPosts = async () => {
      try {
        const _AirtableBlog = new AirTableBlog(undefined, "", undefined, cache);
        const posts: BlogPost[] = await _AirtableBlog.getFeaturedPosts();

        const featuredPost = posts.find((p) => p.isFeatured === "true");

        if (featuredPost) {
          setFeatured(featuredPost);
          const updatedPosts = posts.filter((p) => p.id !== featuredPost.id);
          setPosts(updatedPosts);
        }
      } catch (error) {
        console.error("Error fetching featured blog posts:", error);
        // Handle the error, e.g., redirect to an error page or display a message to the user.
      }
    };
    getPosts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="nc-SectionMagazine5">
      <div className="grid lg:grid-cols-2 gap-6 md:gap-8">
        <CardFeaturedPost featured={featured} />
        <div className="grid gap-6 md:gap-8">
          {posts.map((item: any, index: number) => (
            <CardPost key={index} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default SectionMagazine;