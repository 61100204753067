//import Flow from "./flow/Flow";
import { useEffect  } from "react";
import { Helmet } from "react-helmet";
import Providers from "containers/PageHome/sections/Providers";
import Hero from "./sections/Hero";
import QuickNode from "containers/PageHome/sections/Quicknode";
import Enterprise from "containers/PageHome/sections/Enterprise";
import Benefits from "./sections/Benefits";
import RPCProviders from "shared/RPCProviders/RPCProviders";
import SolanaRPC from "./sections/SolanaRPC";
const Solana = () => {
  useEffect(() => {


  }, []);


  return (
    <>
      <Helmet>
        <title>
         Solana High Throughput RPC Load Balancer | Mainnet | Testnet | Devnet
        </title>
        <meta
          name="description"
          content={
            "Explore Backpac's seamless Solana blockchain connectivity with a single endpoint and multiple RPC providers. Ensure web3 redundancy and scalability effortlessly."
          }
        ></meta>
        <meta
          name="keywords"
          content={
            "Solana RPC providers, Web3 redundancy, QuickNode Marketplace Add-On, Blockchain access, Multi-network connectivity, Solana Mainnet, testnet, devnet, Streamlined application development, Decentralized applications"
          }
        ></meta>
                                <meta
          name="twitter:image"
          content="https://image.nft.backpac.xyz/crop.png"
        />
        <meta
          name="twitter:image:alt"
          content="Backpac's Solana RPC Load Balancer"
        />
        <meta
          property="og:image"
          content="https://image.nft.backpac.xyz/ICON_WHITE_1024_1024.jpg"
        />
        <meta
          property="og:image:alt"
          content="Backpac's Solana RPC Load Balancer"
        />
      </Helmet>
      <Hero />
      <Benefits/>
      <RPCProviders blockchain={"solana"} />
      <SolanaRPC />
      <Providers />
      <QuickNode />
      <Enterprise />
    </>
  );
};
export default Solana;
