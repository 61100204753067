import { FC } from "react";
import { Helmet } from "react-helmet";
import ProductList from "./ProductList";
import QuickNode from "./Providers/QuickNode";
import ProductFeatures from "./Features";
import ProductsHeader from "./ProductsHeader";
export interface ProductsProps {
  className?: string;
}

const Products: FC<ProductsProps> = ({ className = "" }) => {
  return (
    <>
    <Helmet>
        <title>
         RPC Node Provider Backpac Integrations | Web3 Providers
        </title>
        <meta
          name="description"
          content={
            "Backpac boosts throughput of RPC requests across blockchain networks, with user-friendly fault tolerant RPC infrastructure."
          }
        ></meta>
        <meta
          name="keywords"
          content={
            "integration, apps, rpc node, rpc node providers, node operators, throughput, across Blockchain Networks, Web3 Provider Redundancy, Smart RPC Load Balancer, Blockchain RPC Connectivity, Multi-RPC Provider, Blockchain Access, Reliable RPC Service, Web3 Companies, Ultra-Fast Responsiveness, Bulletproof Reliability, Seamless Scaling, RPC Load Balancer, Top RPC Providers, Custom RPC Providers, Ethereum, Coinbase, Polygon, Solana, Binance Smart Chain, Enterprise Plans"
          }
        ></meta>
      </Helmet>
    <ProductsHeader />
    <ProductList />
    <QuickNode />
    <ProductFeatures />
    </>
  );
};
export default Products;