import { useState } from "react";
import BackpacName from "shared/Backpac/Name";
import Slack from 'model/Slack';
import { User } from "interfaces/Auth0";
import { toast, ToastContainer } from 'react-toastify';
import { CheckIcon, ExclamationCircleIcon, PaperAirplaneIcon } from "@heroicons/react/24/outline";
interface AuthenticatedContactProps {
  user: User | undefined;
}
const AuthenticatedContact: React.FC<AuthenticatedContactProps> = ({user}) => {

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);


  const handleSubmit = async (event: any) =>{
    event.preventDefault();
    const subject = "Authenticated - Contact Us"
    //console.log({subject, email, name, message});
    setLoading(true);
    const slack = new Slack(undefined);
    const email = user?.email || "";
    const name = user?.name || "";

    await toast.promise(
      slack.contactUs(email, name, subject, message),
      {
        pending: {
          render(){
            setLoading(true);
            return "Sending message"
          },
          icon: <PaperAirplaneIcon className="w-5 h-5"/>,
        },
        success: {
          render({data}){
            setMessage("");
            setLoading(false);
            return `Message received. Thank you ${name}`
          },
          // other options
          icon: <CheckIcon className="w-5 h-5" />,
        },
        error: {
          render({data}){
            // When the promise reject, data will contains the error
            setLoading(false);
            return `Error: ${data}`
          },
          icon: <ExclamationCircleIcon className="w-5 h-5"/>
        },
      }
  );
  setLoading(false);
  };
  return (
    <div className="bg-white dark:bg-neutral-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <form onSubmit={handleSubmit}>
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
                Feedback
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-neutral-400">
                Share your feedback and or feature request with the{" "}
                <BackpacName /> team.
              </p>

              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="email_address"
                    className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    Your Email Address
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      {/* <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                        workcation.com/
                      </span> */}
                      <input
                        type="text"
                        disabled={true}
                        name="email_address"
                        id="email_address"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 dark:text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        value={user?.email}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    Message
                  </label>
                  <div className="mt-2">
                    <textarea
                      disabled={loading}
                      id="message"
                      name="message"
                      rows={3}
                      value={message}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    />
                  </div>
                  <p className="mt-3 text-sm leading-6 text-gray-600 dark:text-neutral-400">
                    Write a your feedback or feature request.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              disabled={loading}
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900 dark:text-white"
              onClick={() => {
                window.location.replace("/");
              }}
            >
              Home
            </button>
            <button
              disabled={loading}
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {loading ? (
                <>
                  <svg
                    className="animate-spin h-5 w-5 mr-3 ..."
                    viewBox="0 0 24 24"
                  ></svg>
                  Processing...
                </>
              ) : (
                "Send Message"
              )}
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
export default AuthenticatedContact