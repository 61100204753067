import { ServerIcon, CheckIcon, ClipboardIcon } from '@heroicons/react/24/solid';
import ProvidersScreen from "images/screens/Providers2.png";
const features = [
  {
    name: 'Cached RPC Methods by Block Time',
    description:
      'This ensures efficient and rapid access to frequently used data, minimizing reads to the RPC node provider and enhancing overall system performance.',
    icon: ClipboardIcon,
  },
  {
    name: 'RPC Request Failover Redundancy',
    description: 'Backpac introduces a failover feature that guarantees seamless RPC request fulfillment. In the event of a node provider issue, Backpac dynamically switches to an alternative RPC provider',
    icon: ServerIcon,
  },
  {
    name: 'Block Height Checks',
    description: 'By dynamically selecting the RPC node provider with the highest block height, Backpac routes your requests to blockchain nodes with the freshest and most accurate data.',
    icon: CheckIcon,
  },
]

export default function Benefits() {
  return (
    <div className="overflow-hidden bg-white py-8 sm:py-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Deploy faster
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Collective Features
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                By strategically leveraging multiple RPC node provider
                endpoints, Backpac enables you to collectively increase rate
                limits. Distribute requests intelligently, harnessing the
                combined power of diverse providers to enhance your
                application's performance and responsiveness.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon
                        className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={ProvidersScreen}
            alt="Features across multiple RPC Node Providers"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
}
