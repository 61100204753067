import { FC, useState, useEffect } from "react";
import Badge from "shared/Badge/Badge";
import AirTableBlog from "model/AirTableBlog";
import BlogPost from "interfaces/BlogPost";
import { User } from "interfaces/Auth0";
import backpacIcon from "images/backpac.svg";
import moment from 'moment';
  export interface SectionMagazineProps {
    cache: any;
    user: User | undefined;
  }
  const Waitlist: FC<SectionMagazineProps> = ({ cache, user }) => {

    const [posts, setPosts] = useState<BlogPost[]>([]);

    useEffect(() => {
      const getPosts = async () => {
        try {
          const _AirtableBlog = new AirTableBlog(undefined, "", undefined, cache);
          const posts: BlogPost[] = await _AirtableBlog.getFeaturedPosts();

          setPosts(posts);
        } catch (error) {
          console.error("Error fetching featured blog posts:", error);
        }
      };
      getPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div className="bg-white dark:bg-neutral-900">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <a href={`/`}>
              <img
                className="mx-auto h-20 w-auto"
                src={backpacIcon}
                alt="Backpac Inc"
              />
            </a>
          </div>
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
              Your all Signed Up.
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600 dark:text-neutral-400">
              We are onboarding organizations on a rolling basis and will reach
              out shorty to get you setup. If you have any questions, feature
              request or feedback, please feel free to reach out using the contact form below.
            </p>
            {user?.email_verified === false && (
              <p className="mt-2 text-lg leading-8 text-gray-600 dark:text-neutral-400">
                An email has been sent to <b>{user.email}</b> Please follow the
                instructions in the email to verify your Email Address.
              </p>
            )}
          </div>
          <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <article
                key={post.id}
                className="flex max-w-xl flex-col items-start justify-between"
              >
                <div className="flex items-center gap-x-4 text-xs">
                 
                  <Badge
                    href={`/blog?tag=${post.tag.toLowerCase()}`}
                    color="blue"
                    name={post.tag.toUpperCase()}
                  />
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600 dark:text-white">
                    <a href={`/blog/${post.slug}`}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600 dark:text-neutral-400">
                    {post.metaDescription}
                  </p>
                </div>
                <div className="relative mt-8 flex items-center gap-x-4">
                  <img
                    src={post.featured}
                    alt={post.title}
                    className="h-10 w-10 rounded-full bg-gray-50"
                  />
                  <div className="text-sm leading-6">
                    <p className="font-semibold text-gray-900 dark:text-white">
                      <a href={`/blog/${post.slug}`}>
                        <span className="absolute inset-0" />
                        {post.author}
                      </a>
                    </p>
                    <time
                    dateTime={post.date}
                    className="text-gray-500 dark:text-neutral-400"
                  >
                    {moment(post?.date).format("dddd, MMM Do YYYY")}
                  </time>
                    {/* <p className="text-gray-600 dark:text-neutral-400">
                      {post.tag}
                    </p> */}
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    );
  };
  export default Waitlist;