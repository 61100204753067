
const FeaturesAcrossProviders = () => {
  return (
    <section className="relative py-28 bg-gray-900">
      <div className="p-2 relative max-w-2xl mx-auto sm:text-center">
        <div className="relative z-10">
          <h3
            className="font-semibold text-neutral-900 text-3xl xl:text-4xl"
            style={{ color: "#3BA5F3" }}
          >
            Use RPC Methods Across<br /> 
            Node Providers
          </h3>
          <p className="mt-3 text-white dark:text-neutral-300">
            Access custom RPC methods across different RPC node
            providers through a single RPC endpoint
          </p>
        </div>
        <div
          className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]"
          style={{
            background:
              "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)",
          }}
        ></div>
      </div>
      <div className="flex flex-wrap justify-center p-8">
        {/* Without Backpac */}
        <div className="p-4 rounded-lg border border-gray-300 mb-4 mr-2 flex-shrink-0 w-full sm:w-auto">
          <h4 className="text-2xl font-bold mb-4 text-white">
            Without Backpac
          </h4>
          <div className="line-numbers-wr bg-black rounded-lg">
            <pre className="line-numbers language-javascript">
              <code className="language-javascript text-sm whitespace-pre-wrap">
                <ul
                  className="list-inside text-white"
                  style={{ marginLeft: "3px" }}
                >
                  <li key={"without-" + 1}>
                    <span className="text-blue-500">const</span>{" "}
                    <span className="text-white">Web3 = </span>
                    <span className="text-purple-500">require</span>(
                    <span className="text-yellow-500">'web3'</span>);
                  </li>
                  <li key={"without-" + 2} >
                    <span> </span>
                  </li>
                  <li key={"without-" + 3} >
                    <span className="text-gray-500">
                      &#47;&#47;Use QuickNode endpoint
                    </span>
                  </li>
                  <li key={"without-" + 4}>
                    <span className="text-blue-500">const</span>{" "}
                    <span className="text-white">QuickNode = </span>
                    <span className="text-blue-500">new</span> Web3(
                    <span className="text-yellow-500">
                      'rpc.quicknode.com/{"{key}"}'
                    </span>
                    );
                  </li>
                  <li key={"without-" + 5}>
                    <span className="text-gray-500">
                      &#47;&#47;EXECUTE: qn_fetchNFTCollectionDetails
                    </span>
                  </li>
                  <li key={"without-" + 6}>
                    <span className="text-blue-200">
                      “method” :{" "}
                      <span className="text-yellow-500">
                        “qn_fetchNFTCollectionDetails”
                      </span>
                    </span>
                    ;
                  </li>
                  <li key={"without-" + 7}>
                    <span className="text-blue-200">
                      "params" :{" "}
                      <span className="text-yellow-500">
                        <span className="text-orange-500">{"["}</span>
                        “0xC02...6Cc2”
                      </span>
                      <span className="text-orange-500">{"]"}</span>
                    </span>
                    ;
                  </li>
                  <li key={"without-" + 8}>
                    <span> </span>
                  </li>
                  <li key={"without-" + 9}>
                    <span className="text-gray-500">
                      &#47;&#47;Change to Alchemy endpoint
                    </span>
                  </li>
                  <li key={6}>
                    <span className="text-blue-500">const</span> Alchemy ={" "}
                    <span className="text-blue-500">new</span> Web3(
                    <span className="text-yellow-500">
                      'rpc.alchemy.com/{"{key}"}'
                    </span>
                    );
                  </li>
                  <li key={"without-" + 10}>
                    <span className="text-gray-500">
                      &#47;&#47;EXECUTE: alchemy_getTokenMetadata
                    </span>
                  </li>
                  <li key={"without-" + 11}>
                    <span className="text-blue-200">
                      “method” :{" "}
                      <span className="text-yellow-500">
                      "alchemy_getTokenMetadata"
                      </span>
                    </span>
                    ;
                  </li>
                  <li key={"without-" + 12}>
                    <span className="text-blue-200">
                      "params" :{" "}
                      <span className="text-yellow-500">
                        <span className="text-orange-500">{"["}</span>
                        “0xC02...6Cc2”
                      </span>
                      <span className="text-orange-500">{"]"}</span>
                    </span>
                    ;
                  </li>
                </ul>
              </code>
            </pre>
          </div>
        </div>

        {/* Using Backpac */}
        <div
          className="p-5 rounded-lg border mb-4 mr-2 flex-shrink-0 w-full sm:w-auto"
          style={{ borderColor: "#3BA5F3" }}
        >
          <h4 className="text-2xl font-bold mb-4 text-white">With Backpac</h4>
          <div className="line-numbers-wr bg-black rounded-lg">
            <pre className="line-numbers language-javascript">
              <code className="language-javascript text-sm whitespace-pre-wrap">
                <ul
                  className="list-inside text-white"
                  style={{ marginLeft: "3px" }}
                >
                  <li key={1}>
                    <span className="text-blue-500">const</span> Web3 ={" "}
                    <span className="text-purple-500">require</span>(
                    <span className="text-yellow-500">'web3'</span>);
                  </li>
                  <li key={2}>
                    <span> </span>
                  </li>
                  <li key={3}>
                    <span className="text-gray-500">
                      &#47;&#47;Only load Backpac as a provider
                    </span>
                  </li>
                  <li key={4}>
                    <span className="text-blue-500">const</span> Backpac ={" "}
                    <span className="text-blue-500">new</span> Web3(
                    <span className="text-yellow-500">
                      'rpc.backpac.xyz/{"{key}"}'
                    </span>
                    );
                  </li>
                  <li key={5}>
                    <span className="text-gray-500">
                      &#47;&#47;EXECUTE: qn_fetchNFTCollectionDetails
                    </span>
                  </li>
                  <li key={6}>
                    <span className="text-blue-200">
                      “method” :{" "}
                      <span className="text-yellow-500">
                        “qn_fetchNFTCollectionDetails”
                      </span>
                    </span>
                    ;
                  </li>
                  <li key={7}>
                    <span className="text-blue-200">
                      "params" :{" "}
                      <span className="text-yellow-500">
                        <span className="text-orange-500">{"["}</span>
                        “0xC02...6Cc2”
                      </span>
                      <span className="text-orange-500">{"]"}</span>
                    </span>
                    ;
                  </li>
                  <li key={8}>
                    <span> </span>
                  </li>
                  <li key={9}>
                    <span className="text-gray-500">
                      &#47;&#47;EXECUTE: alchemy_getTokenMetadata
                    </span>
                  </li>
                  <li key={10}>
                    <span className="text-blue-200">
                      “method” :{" "}
                      <span className="text-yellow-500">
                      "alchemy_getTokenMetadata"
                      </span>
                    </span>
                    ;
                  </li>
                  <li key={11}>
                    <span className="text-blue-200">
                      "params" :{" "}
                      <span className="text-yellow-500">
                        <span className="text-orange-500">{"["}</span>
                        “0xC02...6Cc2”
                      </span>
                      <span className="text-orange-500">{"]"}</span>
                    </span>
                    ;
                  </li>
                  <li key={12}>
                    <span> </span>
                  </li>
                  <li key={13}>
                    <span> </span>
                  </li>
                </ul>
              </code>
            </pre>
          </div>
        </div>
      </div>
      <div
        className="absolute inset-0 max-w-md mx-auto h-72 blur-[118px]"
        style={{
          background:
            "linear-gradient(152.92deg, rgba(59, 165, 243, 1) 4.54%, rgba(59, 165, 243, 0.5) 34.2%, rgba(59, 165, 243, 0.2) 77.55%)",
        }}
      ></div>
    </section>
  );
};

export default FeaturesAcrossProviders;
