import { useState, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/24/solid"
import StripeUtils from "model/StripeUtils";
import { StripeProduct } from "interfaces/StripeProduct";
import { useAuth0 } from '@auth0/auth0-react';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
export default function Plans() {
  const domain = window.location.origin;
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [product, setProduct] = useState<StripeProduct>();
  const [included, setIncluded] = useState<string[]>([]);
  
  useEffect(() => {
    const getProduct = async () => {
    //setLoading(true);

      //Setup the Stripe object
      const _StripeUtils = new StripeUtils(domain, undefined);

      const products: Array<StripeProduct> = await _StripeUtils.retrieveArweave();
      if(typeof products[0] !== "undefined"){
        setProduct(products[0]);
        setIncluded(JSON.parse(products[0].metadata.included));
      }

      //Stop loading
      //setLoading(false);

      //Show the UI
      //setInit(false);
    };
    //Grab the products and pricing data for the UI
    getProduct();
  }, [domain]);

  const renderPricingItem = (
    pricing: StripeProduct | undefined,
    index: number
  ) => {
    if (typeof pricing !== "undefined") {
      return (
        <div
          key={index}
          className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden ${
            typeof pricing.metadata !== "undefined" &&
            pricing.metadata["recommended"] === "true"
              ? "border-primary-500"
              : "border-neutral-100 dark:border-neutral-700"
          }`}
        >
          {typeof pricing.metadata !== "undefined" &&
            pricing.metadata["recommended"] === "true" && (
              <span className="bg-primary-500 text-white px-3 py-1 tracking-widest text-xs absolute right-3 top-3 rounded-full z-10">
                POPULAR
              </span>
            )}
          <div className="mb-8">
            <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
              {pricing.name}
            </h3>
            <h2 className="text-5xl leading-none flex items-center dark:text-neutral-300">
              <span>${(pricing.price.unit_amount / 100).toFixed(2)}</span>
              <span className="text-lg ml-1 font-normal text-neutral-500 dark:text-neutral-300">
                {pricing.price.recurring.interval}
              </span>
            </h2>
          </div>
          <nav className="space-y-4 mb-8">
            {pricing.features.map((item, index) => (
              <li className="flex items-center" key={index}>
                <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
                  <CheckIcon className="w-5 h-5" aria-hidden="true" />
                </span>
                <span className="text-neutral-700 dark:text-neutral-300 sm:text-m">
                  {item.name}
                </span>
              </li>
            ))}
          </nav>
          <div className="flex flex-col mt-auto">
            {typeof pricing.metadata !== "undefined" &&
            pricing.metadata["recommended"] === "true" ? (
              <ButtonPrimary
                onClick={() => {
                  //Add the plan to the session
                  sessionStorage.setItem("plan", pricing.metadata["plan"]);
                  //Log them into the app
                  !isAuthenticated
                    ? loginWithRedirect()
                    : (document.location.href = "/dashboard");

                  //document.location.href = "/login?plan=" + pricing.metadata['plan'];
                }}
              >
                Get started with {pricing.metadata["plan"]}
              </ButtonPrimary>
            ) : (
              <ButtonSecondary
                onClick={() => {
                  //Add the plan to the session
                  sessionStorage.setItem("plan", pricing.metadata["plan"]);
                  //Log them into the app
                  !isAuthenticated
                    ? loginWithRedirect()
                    : (document.location.href = "/dashboard");
                  //document.location.href = "/login?plan=" + pricing.metadata['plan'];
                }}
              >
                <span className="font-medium">
                  Get started with {pricing.metadata["plan"]}
                </span>
              </ButtonSecondary>
            )}

            {/* <PopupButton
                  id={"GUMgTayZ"}
                  className={"nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors px-4 py-3 sm:px-6 text-sm sm:text-base font-medium" + (typeof pricing.metadata !== "undefined" && pricing.metadata['recommended'] === "true" ? "ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50" : "ttnc-ButtonSecondary border bg-secondary-100 border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-secondary-400 dark:hover:bg-secondary-800")}
                  size={66}
                  medium="demo-test"
                  hidden={{ plan: pricing.metadata['plan'], hubspot_page_url: document.location.href, hubspot_page_name: pricing.metadata['plan'], }}
                >
                  Get started with {pricing.metadata['plan']}
                </PopupButton> */}
            <p className="text-xs text-neutral-500 dark:text-neutral-300 mt-3">
              {pricing.statement_descriptor}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
          <div role="status" className="space-y-2.5 animate-pulse max-w-lg">
            <div className="flex items-center w-full">
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
              <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
            </div>
            <div className="flex items-center w-full max-w-[480px]">
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
              <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
            </div>
            <div className="flex items-center w-full max-w-[400px]">
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div className="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
            </div>
            <div className="flex items-center w-full max-w-[480px]">
              <div className="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
              <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
            </div>
            <div className="flex items-center w-full max-w-[440px]">
              <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
              <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div className="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
            </div>
            <div className="flex items-center w-full max-w-[360px]">
              <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div className="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
            </div>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="bg-white dark:bg-neutral-900 py-8 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
          <h3 className="text-2xl font-bold tracking-tight dark:text-white text-black">
              {product?.name}
            </h3>
            <div className="hidden sm:flex sm:justify-left">
              <img
                src={product?.images[0]}
                alt={"Backpac's Arweave Gateway"}
                className="rounded w-24 h-24 align-left"
                style={{marginRight: '16px'}}
              />
              <p className="mt-6 text-base leading-7 text-lg sm:text-m text-gray-600 dark:text-white">
                {product?.description}
              </p>
            </div>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 dark:gray-900 text-black">
                What’s included
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul className="mt-8 grid grid-cols-1 text-xl sm:text-m leading-6 text-gray-600 sm:grid-cols-1 dark:text-white">
              {included.map((feature, index) => (
                <li key={index} className="flex gap-x-3">
                  <CheckIcon
                    color="#3BA5F3"
                    className="h-6 w-6 flex-none"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            {renderPricingItem(product, 1)}
          </div>
        </div>
      </div>
    </div>
  );
}
