import React, { useState, useEffect  } from "react";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import backpacIcon from "../images/backpac-icon.svg";
import Auth0 from "model/Auth0";
import Waitlist from "./waitlist";
import AuthenticatedContact from "./contactus";
import QuickNode from "containers/PageHome/sections/Quicknode";
import Connect from "containers/PageHome/sections/Connect";
import ConfirmEmail from "./confirmemail";
import Slack from 'model/Slack';
export interface LoginDashboardProps {
  cache: any;
}
const LoginDashboard: React.FC<LoginDashboardProps> = ({cache}) =>{
  const navigate = useNavigate();
  const { isAuthenticated, getIdTokenClaims, user, isLoading } = useAuth0();
  const [organizationName, setOrganizationName] = useState<string>('');
  const [plan, setPlan]  = useState<string>('unsubscribed');
  const [loading, setLoading] = useState(false);
  const [waitlist, setWaitlist] = useState(false);
  const [emailVerified, setSetEmailVerified] = useState(false);
  const [hasOrg, setHasOrg] = useState<boolean>(true);
  const toastConfig: ToastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };
  const slack = new Slack(process.env.REACT_APP_SLACK_WAITLIST_WEBHOOK);

  useEffect(() => {
    const plan = sessionStorage.getItem('plan');
    if(plan !== null){
      setPlan(plan)
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const auth0 = new Auth0();
      const hasOrg = auth0.hasOrg(user);
      const waitlisted = auth0.isWaitlisted(user);
      const _emailVerified = user?.email_verified || false;
      setSetEmailVerified(_emailVerified);
      setWaitlist(waitlisted);
      if (hasOrg === false) {
        setHasOrg(false);

        //If they don't have an Org but there email address is verified.
        const new_user = localStorage.getItem('new_user');
        if(new_user === null && user?.email_verified === true) {
          updateWaitlist(user?.email);
          localStorage.setItem('new_user', user?.email || "");
        }

      }else if(_emailVerified === true){
        //Send them to the dashboard
        viewDashboard();
      }
    }else if(isAuthenticated === false && isLoading === false){
      window.location.replace("/");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user, isLoading]);

  const updateWaitlist = async (email: string | undefined) => {
    await slack.updateWaitlist(email || "");
  }

  const handleRefresh = () => {
    //window.location.reload();
    navigate(0);
  };

  const viewDashboard = async () => {
    try {
      const auth0 = new Auth0();
      const hasOrg = await auth0.hasOrg(user);
      //const waitlisted = auth0.isWaitlisted(user);
      const _emailVerified = user?.email_verified || false;

      if (hasOrg === true && _emailVerified === true) {
        try{
          const claims = await getIdTokenClaims();
          //console.log('claims',claims);
          const did_token = claims?.__raw || "";
          //console.log('did_token',did_token);
          window.location.replace(auth0.dashboardLoginViaSSOUrl(did_token));
        }catch(e){
          handleRefresh();
        }
      } 
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      toast.error(error.message, toastConfig);
    }
  };

  const createAccount = async (e: any) => {
    try {
      setLoading(true);
      toastConfig.toastId = "createAccount";
      e.preventDefault();

      if (typeof organizationName === "undefined") {
        toast.error("Please provide an Organization Name", toastConfig);
      } else {
        //We need a company name slug
        //Strip special characters
        let ORG_NAME = organizationName.replace(/[^\w\s]/g, "");
        //Lowercase the name
        ORG_NAME = ORG_NAME.toLowerCase();
        //Replace any spaces with a hypen
        ORG_NAME = ORG_NAME.replace(/ /g, "-");

        const ORG_DISPLAY_NAME = organizationName.replace(/[^\w\s]/g, "");

        const auth0 = new Auth0();
        const claims = await getIdTokenClaims();
        const did_token = claims?.__raw || "";
        const create = await auth0.createAccountForSocial(did_token, {
          ORG_NAME,
          ORG_DISPLAY_NAME,
          admin: {
            email: user?.email,
          },
          metadata: {
            plan,
            "wait-listed": false,
          },
        });   
        
      if (create.success === true) {
          setHasOrg(true);
          setWaitlist(false);
          viewDashboard();
        } else {
          console.log(create);
          setLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

 if (hasOrg && !waitlist) {
    return (
      <>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-20 w-auto"
              src={backpacIcon}
              alt="Backpac Inc"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Loading your Dashboard
            </h2>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
              <div className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-slate-200 h-10 w-10"></div>
                <div className="flex-1 space-y-6 py-1">
                  <div className="h-2 bg-slate-200 rounded"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                      <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                    </div>
                    <div className="h-2 bg-slate-200 rounded"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </>
    );
  } else  if(emailVerified === false){
    return <ConfirmEmail user={user}/>
  }else if (waitlist) {
    return (
      <>
        <Waitlist user={user} cache={cache}/>
        <AuthenticatedContact user={user}/>
        <QuickNode />
        <Connect />
      </>
    );
  } else {
    return (
      <>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-20 w-auto"
              src={backpacIcon}
              alt="Backpac Inc"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Create your Organization
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={createAccount}
            >
              <div>
                <label
                  htmlFor="organization_name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Organization Name
                </label>
                <div className="mt-2">
                  <input
                    disabled={loading}
                    value={organizationName}
                    onChange={(e) => {
                      setOrganizationName(e.target.value);
                    }}
                    id="organization_name"
                    name="organization_name"
                    type="text"
                    required
                    aria-required={true}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {/* Add a Check box here to confirm terms */}
              </div>
              <div>
                <button
                  disabled={loading}
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {loading ? (
                    <>
                      <svg
                        className="animate-spin h-5 w-5 mr-3 ..."
                        viewBox="0 0 24 24"
                      ></svg>
                      Processing...
                    </>
                  ) : (
                    "Dashboard"
                  )}
                </button>
              </div>
            </form>
          </div>
          <ToastContainer />
        </div>
      </>
    );
  }
};
export default LoginDashboard;