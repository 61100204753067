import { FC } from "react";
import { ServerIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/solid';
import { NoSymbolIcon } from '@heroicons/react/24/outline'
import QuickNodeAddOn from "images/screens/quicknode-add-on-general.png";
const features = [
  {
    name: 'High Throughput',
    description:
      'Supercharge your operations with Backpac\'s cutting-edge technology, ensuring lightning-fast blockchain access and seamless RPC request acceleration.',
    icon: ArrowTrendingUpIcon,
  },
  {
    name: 'No Technical Debt',
    description: 'Unlock innovation without compromise! Backpac offers a future-proof solution, eliminating technical debt and paving the way for limitless possibilities.',
    icon: NoSymbolIcon,
  },
  {
    name: 'API Credits Savings',
    description: 'Optimize efficiency and reduce costs! Backpac intelligently manages read calls to the blockchain, providing substantial savings while maintaining top-notch performance.',
    icon: ServerIcon,
  },
]

export interface ChainstackProps {
  provider?: string;
  featured?: boolean;
}

const Chainstack: FC<ChainstackProps> = ({ provider = "", featured = false }) => {
  return (
    <div className="overflow-hidden bg-white dark:bg-neutral-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
            {featured === true ? <>
                <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Featured App
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
              Chainstack
              </p>
              </> : <>
              <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
               Chainstack
              </h2>
              </>}
              <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-neutral-300">
                Attain web3 redundancy by distributing requests across multiple
                RPC Node Providers. Customize configurations for scalable,
                high-performance RPC requests and a multitude of features using
                a single RPC endpoint.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900 dark:text-white">
                      <feature.icon
                        className="absolute left-1 top-1 h-5 w-5 text-indigo-600 dark:text-neutral-300"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={QuickNodeAddOn}
            alt="QuickNode Add On - QuickNode Marketplace"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
}
export default Chainstack