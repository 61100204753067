import { FC } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import AvatarDropdown from "./AvatarDropdown";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Navigation from "shared/Navigation/Navigation";
import { useAuth0 } from '@auth0/auth0-react';
import Auth0 from "model/Auth0";
export interface MainNav2LoggedProps {}
const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  const { getIdTokenClaims, user } = useAuth0();
  const auth0 = new Auth0();
  const viewDashboard = async () => {
    try {
      const claims = await getIdTokenClaims();
      auth0.viewDashboard(claims, user);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className={`nc-MainNav2Logged relative z-10 ${"onTop "}`}>
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-2">
            <Navigation />
            <div className="hidden sm:block h-6 border-l border-neutral-300 dark:border-neutral-6000"></div>
            <div className="flex">
              <SwitchDarkMode />
              {/* <NotifyDropdown /> */}
            </div>
            {/* <div></div>*/}
            <ButtonSecondary
              onClick={viewDashboard}
              sizeClass="px-4 py-2 sm:px-5"
            >
              Dashboard
            </ButtonSecondary>
            <div></div>
            <AvatarDropdown />
          </div>
          <div className="flex items-center space-x-3 xl:hidden">
            {/* <NotifyDropdown /> */}
            <AvatarDropdown />
            <ButtonSecondary
              onClick={viewDashboard}
              sizeClass="px-4 py-2 sm:px-5"
            >
              Dashboard
            </ButtonSecondary>
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainNav2Logged;