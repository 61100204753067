import { useState, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/24/solid"
import StripeUtils from "model/StripeUtils";
import { StripeProduct } from "interfaces/StripeProduct";
import { useAuth0 } from '@auth0/auth0-react';
export default function Enterprise() {
  const domain = window.location.origin;
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [features, setFeatures] = useState<Array<any>>([]);
  const [product, setProduct] = useState<StripeProduct | any>({metadata:{}, images: []});
  useEffect(() => {
    const getProduct = async () => {
    //setLoading(true);

      //Setup the Stripe object
      const _StripeUtils = new StripeUtils(domain, undefined);

      const product: Array<StripeProduct> = await _StripeUtils.retrieveArweaveEnterprise();
      if(typeof product !== "undefined"){
        setProduct(product[0]);
        setFeatures(product[0].features);
      }

      //Stop loading
      //setLoading(false);

      //Show the UI
      //setInit(false);
    };
    //Grab the products and pricing data for the UI
    getProduct();
  }, [domain]);

  return (
    <div className="bg-white dark:bg-neutral-900 py-2 sm:py-2">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
          <h3 className="text-2xl font-bold tracking-tight dark:text-white text-black">
              {product?.name}
            </h3>
            <div className="hidden sm:flex sm:justify-left">
              <img
                src={product?.images[0]}
                alt={"Backpac's Arweave Gateway"}
                className="rounded w-24 h-24 align-left"
                style={{marginRight: '16px'}}
              />
              <p className="mt-6 text-base leading-7 text-lg sm:text-m text-gray-600 dark:text-white">
                {product?.description}
              </p>
            </div>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 dark:gray-900 text-black">
                What’s included
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul className="mt-8 grid grid-cols-1 gap-4 leading-6 text-xl sm:text-m text-gray-600 dark:text-white sm:grid-cols-2 sm:gap-6">
              {features.map((feature) => (
                <li key={feature.name} className="flex gap-x-3">
                  <CheckIcon
                    color="#3BA5F3"
                    className="h-6 w-5 flex-none"
                    aria-hidden="true"
                  />
                  {feature.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">
                {product.metadata['title']}
                </p>
                {/* <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">
                    1000+
                  </span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                    R/S
                  </span>
                </p> */}

                <a
                  href="/"
                  onClick={(e: any) => {
                    //Add the plan to the session
                    sessionStorage.setItem("plan", product.metadata["plan"]);
                    //Log them into the app
                    !isAuthenticated
                      ? loginWithRedirect()
                      : (document.location.href = "/dashboard");

                    //document.location.href = "/login?plan=" + pricing.metadata['plan'];
                  }}
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get started with {product.metadata["plan"]}
                </a>
                <p className="mt-6 text-m leading-5 text-gray-600">
                  {product.metadata['contact-us']}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
