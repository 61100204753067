import { ServerIcon, CheckIcon, ClipboardIcon } from '@heroicons/react/24/solid';
import ProvidersScreen from "images/screens/Providers2.png";
const features = [
  {
    name: 'Avoid Rate Limits',
    description:
      'Automatically failover to providers when you hit a rate limit error.',
    icon: ClipboardIcon,
  },
  {
    name: 'RPC Request Failover Redundancy',
    description: 'In the event of a node provider issue, Backpac dynamically switches to an alternative RPC provider',
    icon: ServerIcon,
  },
  {
    name: 'Block Height Checks',
    description: 'Backpac routes your requests to blockchain nodes with the freshest and most accurate data.',
    icon: CheckIcon,
  },
]

export default function Benefits() {
  return (
    <div className="overflow-hidden bg-white dark:bg-neutral-900 py-8 sm:py-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600 dark:text-neutral-400">
                Land Transactions
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                Solana RPC Features
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-neutral-400">
                By strategically leveraging multiple RPC node provider
                endpoints, Backpac enables you to avoid rate
                limits by distributing requests intelligently, harnessing the
                combined power of providers to enhance your
                chance of landing transactions.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 dark:text-neutral-400 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900 dark:text-white">
                      <feature.icon
                        className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={ProvidersScreen}
            alt="Features across multiple RPC Node Providers"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
}
