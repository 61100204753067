import { CheckIcon } from "@heroicons/react/24/solid";
import { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
//import { PopupButton } from "@typeform/embed-react";
import { useAuth0 } from '@auth0/auth0-react';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import StripeUtils from "../../model/StripeUtils";
import { StripeProduct } from "interfaces/StripeProduct";
import Enterprise from "containers/PageHome/sections/Enterprise";
import FeaturesCompare from "./FeaturesCompare";
import BookADemo from "./BookADemo";
//import PricingHeading from "components/Heading/PricingHeading";
export interface PageSubcriptionProps {
  className?: string;
  cache?: any
}

export interface PricingItem {
  isPopular: boolean;
  name: string;
  pricing: string;
  desc: string;
  per: string;
  features: string[];
};

const PageSubcription: FC<PageSubcriptionProps> = ({ className = "", cache }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const domain = window.location.origin;
  //const { isLoading } = useAuth0();
  //const [init, setInit] = useState<boolean>(true);
  //const [loading, setLoading] = useState<boolean>(isLoading);
  const [products, setProducts] = useState<Array<StripeProduct>>([]);

  useEffect(() => {
    const getProduct = async () => {
    //setLoading(true);

      //Setup the Stripe object
      const _StripeUtils = new StripeUtils(domain, cache);

      const products: Array<StripeProduct> = await _StripeUtils.retrieveProducts();

      //Set the data for display
      setProducts(products);

      //Stop loading
      //setLoading(false);

      //Show the UI
      //setInit(false);
    };
    //Grab the products and pricing data for the UI
    getProduct();
  }, [domain, cache]);


  const renderPricingItem = (pricing: StripeProduct, index: number) => {
    return (
      <div
        key={index}
        className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden ${
          typeof pricing.metadata !== "undefined" && pricing.metadata['recommended'] === "true"
            ? "border-primary-500"
            : "border-neutral-100 dark:border-neutral-700"
        }`}
      >
        {typeof pricing.metadata !== "undefined" && pricing.metadata['recommended'] === "true" && (
          <span className="bg-primary-500 text-white px-3 py-1 tracking-widest text-xs absolute right-3 top-3 rounded-full z-10">
            POPULAR
          </span>
        )}
        <div className="mb-8">
          <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
            {pricing.name}
          </h3>
          <h2 className="text-5xl leading-none flex items-center dark:text-neutral-300">
            <span>{(pricing.price.unit_amount / 100).toFixed(2)}</span>
            <span className="text-lg ml-1 font-normal text-neutral-500 dark:text-neutral-300">
              {pricing.price.recurring.interval}
            </span>
          </h2>
        </div>
        <nav className="space-y-4 mb-8">
          {pricing.features.map((item, index) => (
            <li className="flex items-center" key={index}>
              <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
                <CheckIcon className="w-5 h-5" aria-hidden="true" />
              </span>
              <span className="text-neutral-700 dark:text-neutral-300">
                {item.name}
              </span>
            </li>
          ))}
        </nav>
        <div className="flex flex-col mt-auto">
          {typeof pricing.metadata !== "undefined" && pricing.metadata['recommended'] === "true" ? (
            <ButtonPrimary onClick={()=>{
              //Add the plan to the session
              sessionStorage.setItem('plan', pricing.metadata['plan']);
              //Log them into the app
              !isAuthenticated ? loginWithRedirect() : document.location.href = "/dashboard";
              
              //document.location.href = "/login?plan=" + pricing.metadata['plan'];
            }}>Get started with {pricing.metadata['plan']}</ButtonPrimary>
          ) : (
            <ButtonSecondary onClick={()=>{
              //Add the plan to the session
              sessionStorage.setItem('plan', pricing.metadata['plan']);
              //Log them into the app
              !isAuthenticated ? loginWithRedirect() : document.location.href = "/dashboard";
              //document.location.href = "/login?plan=" + pricing.metadata['plan'];
            }}>
              <span className="font-medium">Get started with {pricing.metadata['plan']}</span>
            </ButtonSecondary>
          )}
          
            {/* <PopupButton
                id={"GUMgTayZ"}
                className={"nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors px-4 py-3 sm:px-6 text-sm sm:text-base font-medium" + (typeof pricing.metadata !== "undefined" && pricing.metadata['recommended'] === "true" ? "ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50" : "ttnc-ButtonSecondary border bg-secondary-100 border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-secondary-400 dark:hover:bg-secondary-800")}
                size={66}
                medium="demo-test"
                hidden={{ plan: pricing.metadata['plan'], hubspot_page_url: document.location.href, hubspot_page_name: pricing.metadata['plan'], }}
              >
                Get started with {pricing.metadata['plan']}
              </PopupButton> */}
          <p className="text-xs text-neutral-500 dark:text-neutral-300 mt-3">
            {pricing.statement_descriptor}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
    <Helmet>
        <title>Blockchain RPC Access Pricing Plans</title>
        <meta name="description" content={"Explore Backpac's simplified blockchain access pricing plans, including Starter, Basic, and Pro tiers. Get reliable blockchain access with options for multiple regions, custom load balancing, intelligent routing, and more. Contact us for personalized Enterprise Solutions"}></meta>
        <meta name="keywords" content={"Smart RPC Load Balancer pricing, price, subscription, Blockchain RPC Connectivity, Customizable Configurations, Load Balancing, High-Performance RPC, Reputable Providers, Fault Tolerance, Regional Load Balancing, Blockchain Network, API Key, Application Endpoint, Latency Optimization, Performance Enhancement, Custom Load Balancer Rules, RPC Method Routing, Block Height Routing, Smart Caching, Custom Target Groups, Top RPC Providers, Custom RPC Providers"}></meta>
        <meta
          name="twitter:image"
          content="https://image.nft.backpac.xyz/crop.png"
        />
        <meta
          name="twitter:image:alt"
          content="Backpac's Pricing Plans"
        />
        <meta
          property="og:image"
          content="https://image.nft.backpac.xyz/ICON_WHITE_1024_1024.jpg"
        />
        <meta
          property="og:image:alt"
          content="Backpac's Pricing Plans"
        />
      </Helmet>
    <div
      className={`nc-PageSubcription container pb-24 lg:pb-32 ${className}`}
      data-nc-id="PageSubcription"
    >
      <header className="text-center max-w-2xl mx-auto my-20">
        <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {/* <span className="mr-4 text-3xl md:text-4xl leading-none">💎</span> */}
          Simplified blockchain access pricing
        </h2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          Backpac provides businesses with reliable blockchain access and a
          simple pricing model, allowing you to get started in less than a
          minute.
        </span>
      </header>
      <section className="text-neutral-600 text-sm md:text-base overflow-hidden">
        <div className="grid lg:grid-cols-3 gap-5 xl:gap-8">
          {products.map(renderPricingItem)}
        </div>

        {/* <stripe-pricing-table
          pricing-table-id="prctbl_1O51q8EPoKQre9qhzpCPu8T1"
          publishable-key="pk_live_51LsUDQEPoKQre9qhSO7WYEdVaqxa4lNEW5JK0REWkz3CBqpQhloZ6iL4AWLD2LEmZBHICLFXXMIeAbKM3XxKz1d4007GI5IxQ7"
        ></stripe-pricing-table> */}
        {/* <div className="relative py-16 ">
          <PricingHeading
            desc="For enterprise clients seeking over one billion monthly requests, Backpac is ready to craft a personalized plan tailored to your development requirements. Our offering includes SLAs, round-the-clock engineering support, and exclusive pricing options"
          >
          Looking for Enterprise Solutions?
          </PricingHeading>
        </div> */}
      <BookADemo/>
      <FeaturesCompare />
      {/* Enterprise */}
      <Enterprise />
      
      {/* <div className="w-full relative py-24">
        <div className="container -mt-10 lg:-mt-16">
          <div className="relative bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row">
            <div className="w-32 lg:w-44 flex-shrink-0 mt-12 sm:mt-0">
            <ButtonPrimary onClick={()=>{
              document.location.href = "/contact?plan=enterprise";
            }}>Contact us</ButtonPrimary>
            </div>
           
            <div className="pt-5 md:pt-1 md:ml-6 xl:ml-14 flex-grow">
              <div className="max-w-screen-sm ">
                <h2 className="inline-flex items-center text-2xl sm:text-3xl lg:text-4xl font-semibold">
                  <span>Looking for Enterprise Solutions?</span>
                </h2>
                <span className="block mt-4 text-sm text-neutral-500 dark:text-neutral-400">
                For enterprise clients seeking high throughput RPC requests, Backpac is ready to craft a personalized plan tailored to your requirements. Our offering includes SLAs, engineering support.
                </span>
              </div>
   
            </div>

          </div>
        </div>
      </div> */}
      {/* ====================== Enterprise ====================== */}


      </section>
    </div>
    </>
  );
};

export default PageSubcription;
